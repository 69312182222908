import {
  DeleteTwoTone,
  QuestionCircleOutlined,
  EditTwoTone,
  PieChartTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  message,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import CreateProject from "./CreateProject";
import BreadCrumbs from "../BreadCrumbs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routes } from "../../util/constants";
import moment from "moment";

export default function Project(props) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [orgName, setOrgName] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editRecord, setEditRecord] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  const [updateLoading, setUpdateLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN" || access_level === "ORGANIZATION") {
      const org_id = props.location.state.orgId;
      const org_name = props.location.state.orgName;
      setOrgId(org_id);
      setOrgName(org_name);
      setLoading(true);
    }
    getAllProjectsById();
  }, []);

  const getAllProjectsById = async () => {
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN") {
      setIsAdmin(true);
    }
    if (access_level === "ORGANIZATION" || access_level === "ADMIN") {
      setLoading(true);
      let response = await axios.get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project/getallprojectbyid?organization_id=${props.location.state.orgId}`
      );
      console.log("Response get all projects by ID", response.data);
      setAllProjects(response.data);
      setLoading(false);
    } else {
      setLoading(true);
      const url =
        " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/access-level-projects";
      const token = localStorage.getItem("token");
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          console.log("response from project level api", res.data);
          setAllProjects(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.info(err.response.data.message);
          console.log("error from token api", err);
        });
    }
  };

  const handleDeleteProject = async (data) => {
    let response = await axios
      .delete(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project?project_id=${data.project_id}`
      )
      .then((response) => {
        console.log("Delete Response", response);
        if (response.status === 200) {
          message.success("Project deleted successfully !!");
          getAllProjectsById();
        }
      })
      .catch((err) => {
        console.log("Error===>", err);
      });
  };

  const navigateToModules = (record) => {
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN" || access_level === "ORGANIZATION") {
      navigate(routes.MODULES, { state: record });
    } else {
      navigate(routes.PROLEVELMODULE, { state: record });
    }
  };

  const navigateToCharts = (record) => {
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN" || access_level === "ORGANIZATION") {
      navigate(routes.CHARTS, { state: record });
    } else {
      navigate(routes.CHARTS, { state: record });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "",
      width: 50,
      render: (record, value, index) => {
        return <div key={index + 1}>{index + 1}</div>;
      },
    },
    {
      title: "Project Name",
      key: "",
      dataIndex: "",
      width: 120,
      render: (record) => {
        return (
          <Typography.Text
            key={record.project_id}
            type="link"
            onClick={() => {
              navigateToModules(record);
            }}
            className="underline-effect"
          >
            {record.name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: 120,
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      width: 100,
      render: (record) => {
        return (
          <p key={record}>{moment(record.createdAt).format("MMMM Do YYYY")}</p>
        );
      },
    },
    {
      title: "Actions",
      key: "",
      dataIndex: "",
      width: 100,
      render: (record) => {
        return (
          <>
            <Space size="middle">
              <Tooltip title="View Charts">
                <PieChartTwoTone
                  key={record.project_id}
                  twoToneColor="#5046e5"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    navigateToCharts(record);
                  }}
                />
              </Tooltip>
              <Tooltip title="Edit Project">
                {isAdmin ? (
                  <EditTwoTone
                    twoToneColor="#0089eb"
                    style={{ fontSize: "17px" }}
                    onClick={() => {
                      showEditModal(record);
                    }}
                  />
                ) : (
                  <></>
                )}
              </Tooltip>
              <Tooltip title="Delete Project">
                <Popconfirm
                  title="Are you sure, you want to delete the project?"
                  onConfirm={() => {
                    handleDeleteProject(record);
                  }}
                  onCancel={() => getAllProjectsById()}
                  icon={<QuestionCircleOutlined style={{ colour: "red" }} />}
                >
                  {isAdmin ? (
                    <DeleteTwoTone
                      disabled={true}
                      twoToneColor="red"
                      style={{ fontSize: "17px" }}
                    />
                  ) : (
                    <></>
                  )}
                </Popconfirm>
              </Tooltip>
            </Space>
          </>
        );
      },
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const showEditModal = (record) => {
    setProjectName(record.name);
    setProjectDescription(record.description);
    setEditModal(true);
    setEditRecord(record);
  };

  const handleEditModalCancel = () => {
    setEditModal(false);
    setUpdateLoading(false);
  };

  const onChangeProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const onChangeProjectDesc = (event) => {
    setProjectDescription(event.target.value);
  };

  const onSubmit = async (event) => {
    setUpdateLoading(true);
    let projectObject = {
      project_id: editRecord.project_id,
      name: event.projectName,
      organization_id: editRecord.organization_id,
      description: event.projectDescription,
    };
    let response = await axios
      .post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project/update-project`,
        projectObject
      )
      .then((response) => {
        console.log("Update response", response.data);
        if (response.data.statusCode === 200) {
          setUpdateLoading(false);
          getAllProjectsById();
          handleEditModalCancel();
          message.success("Project updated successfully!!");
        }
      })
      .catch((err) => {
        console.log("Error in Edit==>", err);
      });
  };

  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="d-flex flex-row">
              <div className="pages-header">Projects</div>
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div
          style={{
            position: "absolute",
            right: "16px",
            margin: "-23px 16px 0 0",
          }}
        >
          {isAdmin ? (
            <Button
              type="primary"
              style={{ background: "#5046e5" }}
              onClick={showModal}
            >
              Create Project
            </Button>
          ) : (
            <></>
          )}
          <Modal
            open={visible}
            title="Create Project"
            onCancel={handleModalCancel}
            destroyOnClose={true}
            style={{ top: 20 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            width={500}
          >
            {isAdmin ? (
              <CreateProject
                getAllProjectsById={getAllProjectsById}
                handleModalCancel={handleModalCancel}
                orgId={orgId}
                orgName={orgName}
              />
            ) : (
              <></>
            )}
          </Modal>
        </div>

        <div className="my-4 cloud-charts">
          <div className="card mt-5">
            <div className="card-body p-5">
              <Row></Row>
              <Table
                size="middle"
                columns={columns}
                dataSource={allProjects}
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ y: 450 }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={editModal}
        title="Edit Project"
        onCancel={handleEditModalCancel}
        destroyOnClose={true}
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={600}
      >
        <div>
          <Form onFinish={onSubmit} name="project">
            <Form.Item name="projectName">
              <Input
                placeholder="Enter project name"
                name="projectName"
                defaultValue={editRecord.name}
                onChange={onChangeProjectName}
              />
            </Form.Item>
            <Form.Item name="projectDescription">
              <Input
                placeholder="Enter description"
                name="projectDescription"
                defaultValue={editRecord.description}
                onChange={onChangeProjectDesc}
              />
            </Form.Item>
            <Form.Item className="float-end f-right orgbutton">
              <Button
                className="close-modal me-3"
                onClick={handleEditModalCancel}
              >
                Close
              </Button>
              &nbsp;
              <Button
                className="ok-modal"
                type="primary"
                htmlType="submit"
                loading={updateLoading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
