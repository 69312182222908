import { Col, Empty, Row, Select, Spin, message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Colors,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";

import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Colors
);

var initialOrgName = [];
var initialOrgId = [];
var initialProjectName = [];
var initialProjectId = [];
var initialModuleName = [];
var initialModuleId = [];

const { Option } = Select;

export default function DashboardCard2() {
  const [initialOrg, setInitialOrg] = useState("");
  const [orgId, setOrgId] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialProject, setInitialProject] = useState("");
  const [projectId, setProjectId] = useState("");
  const [initialModule, setInitialModule] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [modules, setModules] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [pieLabel, setPieLable] = useState([]);
  const [count, setCount] = useState([]);
  const [totalItem, setTotalItem] = useState([]);
  const [implemented, setImplemented] = useState([]);
  const [notImplemented, setNotImplemented] = useState([]);
  const [barLabels, setBarLabels] = useState([]);
  const [percentageData, setPercentageData] = useState([]);
  const [percentageLabel, setPercentageLabel] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem(
      "CognitoIdentityServiceProvider.5aikoaqjg4necgijavjq6ttk36.azuread_shruti.goyal@yash.com.accessToken"
    );
    getAllOrganizations();
  }, []);

  const getModuleScore = (project_id) => {
    setChartLoading(true);
    axios
      .get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-overall-score?project_id=${project_id}`
      )
      .then((res) => {
        console.log("res1===", res.data);
        const data = res.data;
        let chart_level = [];
        let chart_value = [];
        let yes_count = [];
        data.forEach((element) => {
          chart_level.push(element.name);
          chart_value.push(element.percentage);
          yes_count.push(count);
        });
        setPieLable(chart_level);
        setPieData(chart_value);
        setCount(yes_count);
        setChartLoading(false);
      })
      .catch((error) => {
        console.log("errro-------", error);
      });
  };

  const getMatuarityScore = (project_id) => {
    axios
      .get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-maturity-score?project_id=${project_id}`
      )
      .then((res) => {
        const data = res.data[0];
        console.log("response", res.data[0]);
        let label = Object.keys(data);
        let values = [];
        label.forEach((key) => {
          values.push(data[key]);
        });
        setChartData(values);
        setChartLabel(label);
      })
      .catch((error) => {
        console.log("error from maturity chart===", error);
      });
  };

  const getAllOrganizations = async () => {
    setSelectLoading(true);
    setChartLoading(true);
    let allOrg = [];
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN") {
      setIsAdmin(true);
    }
    const token = localStorage.getItem("token");
    let response = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization/get-all-organizations`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("Dashboard2 Org Response", response.data);
    allOrg = response.data;
    initialOrgName = allOrg[0].name;
    initialOrgId = allOrg[0].organization_id;
    setInitialOrg(initialOrgName);
    setOrgId(initialOrgId);
    setOrganizations(response.data);
    setSelectLoading(false);
    getProjectsByOrgId(initialOrgId);
  };

  const getItemImplemented = (project_id) => {
    axios
      .get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-items-implemented?project_id=${project_id}`
      )
      .then((res) => {
        console.log("response dash 2=======", res.data);
        const data = res.data;
        let process = [];
        let dataset1 = [];
        let dataset2 = [];
        let dataset3 = [];
        data.forEach((element) => {
          process.push(element.Module);
          dataset1.push(element.TotalItems);
          dataset2.push(element.Implemented);
          dataset3.push(element.TotalItems - element.Implemented);
        });
        setBarLabels(process);
        setTotalItem(dataset1);
        setImplemented(dataset2);
        setNotImplemented(dataset3);
      })
      .catch((err) => {
        console.log("error------", err);
      });
  };

  const getPercentage = (project_id) => {
    axios
      .get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-percentage-chart?project_id=${project_id}`
      )
      .then((res) => {
        const data = res.data[0];
        let labels = [];
        let percentageData = [];
        let percentageActualData = [];
        labels = Object.keys(data);
        percentageData = Object.values(data);
        percentageData.forEach((element) => {
          let x = element.split("%")[0];
          percentageActualData.push(x);
        });
        setPercentageData(percentageActualData);
        setPercentageLabel(labels);
      })
      .catch((error) => {
        console.log("error from -------", error);
      });
  };

  const getProjectsByOrgId = async (organization_id) => {
    let allProject = [];
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN") {
      setIsAdmin(true);
    }
    if (access_level === "ORGANIZATION" || access_level === "ADMIN") {
      setLoading(true);
      let response = await axios.get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project/getallprojectbyid?organization_id=${organization_id}`
      );
      console.log("Dashboard2 get all projects by ID", response.data);
      allProject = response.data;
      initialProjectName = allProject[0].name;
      initialProjectId = allProject[0].project_id;
      setInitialProject(initialProjectName);
      setProjects(response.data);
      getMatuarityScore(initialProjectId);
      getModuleScore(initialProjectId);
      getItemImplemented(initialProjectId);
      getPercentage(initialProjectId);
      setLoading(false);
    } else {
      setLoading(true);
      const url =
        " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/access-level-projects";
      const token = localStorage.getItem("token");
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          console.log("dashboard2 from project level api", res.data);
          allProject = res.data;
          initialProjectName = allProject[0].name;
          initialProjectId = allProject[0].project_id;
          setInitialProject(initialProjectName);
          setProjects(res.data);
          getMatuarityScore(initialProjectId);
          getModuleScore(initialProjectId);
          getItemImplemented(initialProjectId);
          getPercentage(initialProjectId);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.info(err.response.data.message);
          console.log("error from token api", err);
        });
    }
  };

  const organizationsOptions = organizations.map((org) => {
    return (
      <Option name={org.name} value={org.organization_id}>
        {org.name}
      </Option>
    );
  });

  const projectOptions = projects.map((project) => {
    return (
      <Option name={project.name} value={project.project_id}>
        {project.name}
      </Option>
    );
  });

  const moduleOption = modules.map((module) => {
    return (
      <Option name={module.name} value={module.module_id}>
        {module.name}
      </Option>
    );
  });

  const handleOrganizationChange = (value) => {
    let filteredData = organizations.filter((org) => {
      if (value == org.organization_id) {
        setInitialOrg(org.name);
      }
    });
    setOrgId(value);
    getProjectsByOrgId(value);
  };

  const handleProjectChange = (value) => {
    let filteredData = projects.filter((project) => {
      if (value == project.project_id) {
        setInitialProject(project.name);
      }
    });
    setProjectId(value);
    getMatuarityScore(value);
    getModuleScore(value);
    getItemImplemented(value);
    getPercentage(value);
    getAllModules();
  };

  const getAllModules = async () => {
    let allModules = [];
    try {
      const response = await axios.get(
        "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/module/getall-module"
      );
      console.log("Dashboard2 Module dataaa ==>", response.data.body);
      allModules = response.data.body;
      initialModuleName = allModules[0].name;
      initialModuleId = allModules[0].module_id;
      setInitialModule(initialModuleName);
      setModules(response.data.body);
    } catch (error) {
      console.error("Error fetching data from backend:", error);
    }
  };

  const handleModuleChange = (value) => {
    let filteredData = modules.filter((module) => {
      if (value == module.module_id) {
        setInitialModule(module.name);
      }
    });
    setModuleId(value);
  };

  const options = {
    barPercentage: 0.9,
    scales: {
      x: {
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: "CATEGORIES",
          font: {
            size: 18,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        font: {
          weight: "bold",
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Maturity Score",
        font: {
          size: 18,
        },
        padding: {
          bottom: 50,
          top: 20,
        },
      },
    },
  };

  const data = {
    labels: chartLabel,
    datasets: [
      {
        data: chartData,
      },
    ],
  };

  const optionsPie = {
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.dataset.data[context.dataIndex] + "%";
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: "right",
        labels: {
          padding: 30,
        },
        padding: {
          bottom: 20,
        },
      },
      title: {
        display: true,
        text: "Overall Module Score",
        font: {
          size: 18,
        },
      },
    },
  };

  const dataPie = {
    labels: pieLabel,
    datasets: [
      {
        height: "500px",
        data: pieData,
        borderWidth: 1,
      },
    ],
  };

  const optionsBar = {
    scales: {
      y: {
        min: 0,
        max: 400,
        ticks: {
          stepSize: 50,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Items Implemented in each Category",
        font: {
          size: 18,
          weight: "bold",
        },
        padding: {
          bottom: 20,
          top: 20,
        },
      },
    },
  };

  const dataBar = {
    labels: barLabels,
    datasets: [
      {
        label: "Total Item",
        data: totalItem,
      },
      {
        label: "Implemented",
        data: implemented,
      },
      {
        label: "Not Implemented",
        data: notImplemented,
      },
    ],
  };

  const optionsPercentage = {
    barPercentage: 0.9,
    scales: {
      x: {
        min: 0,
        max: 100,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          format: {
            style: "percent",
          },
        },
      },
    },
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.dataset.data[context.dataIndex] + "%";
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: (val, context) => `${val}%`,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Percentage Implementation",
        font: {
          size: 18,
        },
        padding: {
          bottom: 50,
          top: 20,
        },
      },
    },
  };

  const dataPercentage = {
    labels: percentageLabel,
    datasets: [
      {
        data: percentageData,
      },
    ],
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={11} xxl={11}>
          <Grid item xs={6} md={4} xl={2}>
            <Card
              variant="none"
              sx={{
                m: 2,
                margin: "-16px",
              }}
            >
              <CardContent
                sx={{
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  float: "none",
                  margin: "  7px 0 -7px 0",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#5046e5",
                      fontWeight: "bold",
                    }}
                  >
                    Organization
                  </Typography>
                  &nbsp; &nbsp;
                  <Select
                    showSearch
                    value={initialOrg}
                    style={{ width: 250 }}
                    placeholder="Select Organization"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleOrganizationChange}
                    loading={selectLoading}
                  >
                    {organizationsOptions}
                  </Select>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Col>
        &nbsp; &nbsp;
        <Col xs={24} sm={24} md={24} lg={12} xl={11} xxl={11}>
          <Grid item xs={6} md={4} xl={2}>
            <Card
              variant="none"
              sx={{
                m: 2,
                margin: "-16px",
              }}
            >
              <CardContent
                sx={{
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  float: "none",
                  margin: "  7px 0 -7px 0",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: "#5046e5",
                      fontWeight: "bold",
                    }}
                  >
                    Project
                  </Typography>
                  &nbsp; &nbsp;
                  <Select
                    showSearch
                    value={initialProject}
                    style={{ width: 250 }}
                    placeholder="Select Project"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleProjectChange}
                  >
                    {projectOptions}
                  </Select>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Col>
      </Row>
      {/* <hr /> */}

      {/* <>
          <Col>
            <label>
              <h3 style={{ color: "#2c384af2" }}>Module&nbsp; &nbsp;</h3>
            </label>
          </Col>
          <Col>
            <Select
              showSearch
              value={initialModule}
              style={{ width: 200 }}
              placeholder="Select Module"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleModuleChange}
            >
              {moduleOption}
            </Select>
          </Col>
        </> */}

      {chartLoading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]} style={{ margin: "20px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card
                sx={{ padding: "14px", margin: " 5px", borderRadius: "1px" }}
              >
                <div style={{ height: "400px" }}>
                  {(() => {
                    if (chartData.length === 1) {
                      return (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{
                            height: 100,
                            margin: "0 0 0 0",
                          }}
                        />
                      );
                    } else {
                      // return <Bar options={options} data={data} />;
                    }
                  })()}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card
                sx={{ padding: "14px", margin: "5px", borderRadius: "1px" }}
              >
                <div style={{ height: "400px" }}>
                  {(() => {
                    if (pieData.length === 0) {
                      return (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{
                            height: 100,
                            margin: "0 0 0 0",
                          }}
                        />
                      );
                    } else {
                      // return (
                      //   <Pie
                      //     data={dataPie}
                      //     options={optionsPie}
                      //     width="500px"
                      //     height="500px"
                      //   />
                      // );
                    }
                  })()}
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ margin: "20px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card
                sx={{ padding: "14px", margin: " 5px", borderRadius: "1px" }}
              >
                <div style={{ height: "400px" }}>
                  {(() => {
                    if (totalItem.length === 1) {
                      return (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{
                            height: 100,
                            margin: "0 0 0 0",
                          }}
                        />
                      );
                    } else {
                      // return <Bar options={optionsBar} data={dataBar} />;
                    }
                  })()}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card
                sx={{ padding: "14px", margin: " 5px", borderRadius: "1px" }}
              >
                <div style={{ height: "400px" }}>
                  {(() => {
                    if (percentageData.length === 0) {
                      return (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{
                            height: 100,
                            margin: "0 0 0 0",
                          }}
                        />
                      );
                    } else {
                      // return (
                      //   <Bar
                      //     options={optionsPercentage}
                      //     data={dataPercentage}
                      //   />
                      // );
                    }
                  })()}
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
