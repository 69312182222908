import React, { useState } from "react";
import { Layout } from "antd";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";
import Project from "../Organization/Project";
import moment from "moment";
import { routes } from "../../util/constants";
import Dashboard from "../Dashboard";
import User from "../User/User";
import { useLocation } from "react-router-dom";
import OrgDashboard from "../Organization/OrgDashboard";
import ProjectData from "../Organization/ProjectData";
import MasterData from "../MasterData/MasterData";
import Chart from "../Charts/ProjectCharts/Chart";
import ModuleChart from "../Charts/ModuleCharts/Chart";
import OrgChart from "../Charts/OrgCharts/Chart";
import Module from "../Modules/Module";
import ModuleReport from "../Reports/ModuleReport";

const { Sider, Content, Footer } = Layout;

export default function AdminDashboard(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const [viewToggleBtn, setViewToggleBtn] = useState(true);
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
    setSideCollapsed(false);
  };

  // const onSwitchChange = (checked) => {
  //   setSideCollapsed(checked);
  //   if (!sideCollapsed) {
  //     setViewToggleBtn(false);
  //   }
  // };

  const handleMouseEnter = () => {
    setViewToggleBtn(true);
  };

  const handleMouseLeave = () => {
    setViewToggleBtn(false);
  };

  const { pathname } = props.location;

  function renderPathName(pathname) {
    switch (pathname) {
      case routes.USERS:
        return <User location={location} />;
      case routes.ORGANIZATION:
        return <OrgDashboard location={location} />;
      case routes.PROJECTS:
        return <Project location={location} />;
      case routes.PROJECTDATA:
        return <ProjectData location={location} />;
      case routes.MASTERDATA:
        return <MasterData location={location} />;
      case routes.CHARTS:
        return <ModuleChart location={location} />;
      case routes.PROJECTMODULECHARTS:
        return <ModuleChart location={location} />;
      case routes.MODULES:
        return <Module location={location} />;
      case routes.PROJECTCHARTS:
        return <Chart location={location} />;
      case routes.PROLEVELCHARTS:
        return <Chart location={location} />;
      case routes.ORGCHARTS:
        return <OrgChart location={location} />;
      case routes.ADMIN_DASHBOARD:
        return <Dashboard location={location} />;
      case routes.PROJECTSBYID:
        return <Project location={location} />;
      case routes.PROLEVELMODULE:
        return <Module location={location} />;
      case routes.PROLEVELPROJECTDATA:
        return <ProjectData location={location} />;
      case routes.REPORTS:
        return <ModuleReport location={location} />;
      default:
        return <Dashboard location={location} />;
    }
  }

  return (
    <>
      <Layout className="admin-dash ">
        <Sider
          width={260}
          breakpoint="sm"
          collapsedWidth={sideCollapsed ? "80" : "0"}
          theme="light"
          trigger={null}
          collapsible
          collapsed={sideCollapsed ? sideCollapsed : collapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={collapsed ? "display-switch" : ""}>
            <div className={!sideCollapsed ? "toogle-off" : "toogle-on"}>
              <span
                className={
                  sideCollapsed && !viewToggleBtn
                    ? "collapsed-switch-visible"
                    : ""
                }
              >
                {/* <Switch
                  checked={sideCollapsed}
                  onChange={onSwitchChange}
                  size="small"
                /> */}
              </span>
            </div>
          </div>
          <SiderMenu
            history={props.history}
            user={props.user}
            collapsed={collapsed}
          ></SiderMenu>
        </Sider>
        <Layout className="site-layout">
          <HeaderAdmin
            toggle={toggle}
            collapsed={collapsed}
            user={props.user}
            signOut={props.signOut}
          />
          <Content
            style={{
              margin: "30px",
              minHeight: "100vh",
            }}
          >
            {renderPathName(pathname)}
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            ©{moment().year()} Created by EAST-Team
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}
