import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../BreadCrumbs";
import AntdCustomTable from "./AntdCustomTable";

export default function ProjectData(props) {
  console.log("Data from state", props.location.state);
  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">
                {props.location.state.moduleName}
              </div>
              <Breadcrumb
                location={props.location}
                moduleName={props.location.state.moduleName}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <AntdCustomTable props={props.location} />
          </Col>
        </Row>
      </div>
    </>
  );
}
