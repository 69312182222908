import React, { useState, useEffect } from "react";

import { Card, Empty, Spin, Select } from "antd";

import ReactApexChart from "react-apexcharts";
import axios from "axios";

export default function Spiderchart({ projectId, moduleId }) {
  const [data, setData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState([]);
  // const [values, setValues] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const { Option } = Select;

  useEffect(() => {
    asyncFetch();
  }, []);

  const handleChange = (value) => {
    let obj = {};
    console.log("selected valueeeee", value, data);
    data.forEach((element) => {
      if (element["moduleName"] === value) {
        obj = element;
        return;
      }
    });
    console.log("selected objectttttttttt", obj);
    let processObj = obj["processes"];
    console.log("processes of selected module", processObj);
    let keys_array = [];
    let values_array = [];
    // Object.keys(processObj).forEach((key) => {
    //   keys_array.push(key);
    //   values_array.push(processObj[key]);
    // });
    processObj.map((o, index) => {
      console.log("inside spider chart map", o, index);
      Object.keys(o).map((p) => {
        keys_array.push(p);
        values_array.push(Number(o[p].toFixed(2)));
      });
    });

    setKeys(keys_array);
    // setValues(values_array);

    // let keys = ["DevOps", "Development", "Security", "Testing"];
    // let values = [];
    // values.push(obj["DevOps"]);
    // values.push(obj["Development"]);
    // values.push(obj["Security"]);
    // values.push(obj["Testing"]);

    let chartOptions = {
      series: [
        {
          name: "Maturity Score",
          data: values_array,
        },
      ],
      options: {
        chart: {
          type: "radar",
          height: "350px",
        },
        title: {
          // text: "chart basic",
        },
        xaxis: {
          categories: keys_array,
        },
      },
    };
    setChartOptions(chartOptions);
  };
  let selectedOption;

  const asyncFetch = async () => {
    setLoading(true);
    const params = {
      project_id: projectId,
    };
    let optionsArray = [];
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/maturity-score-project-process";
    await axios
      .get(url, { params })
      .then((res) => {
        console.log("response of spider chartttttttt", res);
        if (res.data.length === 0) {
          setLoading(false);
          return;
        }
        res.data.forEach((item) => {
          optionsArray.push(item["moduleName"]);
        });
        let process_obj = res.data[0]["processes"];
        console.log("processes of first spider", process_obj);
        let values = [];
        let keys = [];
        // Object.keys(process_obj).forEach((key, i) => {
        //   console.log("index inside spider for each", i);

        //   keys.push(process_obj);
        //   values.push(process_obj[key]);
        // });
        process_obj.map((o, index) => {
          console.log("inside spider chart map", o, index);
          Object.keys(o).map((p) => {
            keys.push(p);
            values.push(Number(o[p].toFixed(2)));
          });
        });

        console.log("key values of spider", keys, values);
        let c_options = {
          series: [
            {
              name: "Maturity Score",
              data: values,
            },
          ],
          options: {
            chart: {
              type: "radar",
              height: "400px",
            },
            title: {
              // text: "chart basic",
            },
            xaxis: {
              categories: keys,
            },
          },
        };
        setChartOptions(c_options);

        setOptionsData(optionsArray);
        setData(res.data);
        setKeys(keys);

        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
      });
  };

  return (
    <Card title="Select module to view Spider charts">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div>
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return (
                <div>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    defaultValue={optionsData[0]}
                    style={{ width: "180px" }}
                  >
                    {optionsData.map((option, index) => (
                      <Option key={index} value={option}></Option>
                    ))}
                  </Select>

                  <ReactApexChart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type="radar"
                    width={500}
                    height={400}
                  />
                </div>
              );
            }
          })()}
        </div>
      )}
    </Card>
  );
}
