import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Colors,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Empty, Spin } from "antd";

ChartJS.register(ArcElement, Colors, Tooltip, Legend);

export default function PieChartDashboard(props) {
  const [pieData, setPieData] = useState([]);
  const [pieLabel, setPieLable] = useState([]);
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await axios.get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/org-overall-score?organization_id=${props.orgId}`
      );
      console.log("Org data", response.data);
      const data = response.data;
      let chart_level = [];
      let chart_value = [];
      let yes_count = [];
      data.forEach((element) => {
        chart_level.push(element.name);
        chart_value.push(element.percentage);
        yes_count.push(count);
      });
      setPieLable(chart_level);
      setPieData(chart_value);
      setCount(yes_count);
      setLoading(false);
    })();
  }, [props.orgId]);

  const options = {
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.dataset.data[context.dataIndex] + "%";
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 30,
        },
      },
      title: {
        display: true,
        text: "Overall Project Score",
        font: {
          size: 18,
        },
      },
    },
  };

  const data = {
    labels: pieLabel,
    datasets: [
      {
        height: "500px",
        data: pieData,
        backgroundColor: ["#145DA0"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <>
          {(() => {
            if (pieData.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return (
                <div style={{ height: "350px" }}>
                  <Pie
                    data={data}
                    options={options}
                    width="500px"
                    height="500px"
                  />
                </div>
              );
            }
          })()}
        </>
      )}
    </>
  );
}
