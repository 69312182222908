import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import axios from "axios";

export default function CreateOrganization(props) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeDesc = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    let organization = {
      organization: [
        {
          name: name,
          description: description,
        },
      ],
    };
    let response = await axios.post(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization`,
      organization
    );
    console.log("Response Create Org", response);
    if (response.data.statusCode === 201) {
      message.success("Organization created successfully!!");
      setLoading(false);
    } else {
      message.error("Cannot create organization");
    }
    props.handleModalCancel();
    props.getAllOrganizations();
  };

  return (
    <>
      <Form onFinish={onSubmit} name="organization">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please add project name...",
            },
          ]}
        >
          <Input
            placeholder="Enter organization name"
            name="name"
            value={name}
            onChange={onChangeName}
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Please add description...",
            },
          ]}
        >
          <Input
            placeholder="Enter description"
            name="description"
            value={description}
            onChange={onChangeDesc}
          />
        </Form.Item>

        <Form.Item className="float-end f-right orgbutton">
          <Button
            className="close-modal me-3"
            onClick={props.handleModalCancel}
          >
            Close
          </Button>
          &nbsp;
          <Button
            className="ok-modal"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
