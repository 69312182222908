import React, { useState, useEffect } from "react";

import { Card, Empty, Spin, Table, Select } from "antd";

import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { elements } from "chart.js";
export default function Spiderchart({ organizationId }) {
  const [data, setData] = useState([]);
  const [projectData, setProjecttData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const { Option } = Select;

  const params = {
    organization_id: organizationId,
  };
  useEffect(() => {
    asyncFetch();
  }, []);

  const handleChange = (value) => {
    let obj = {};
    console.log("selected valueeeee", value, data);
    setProjectName(value);
    data.forEach((element) => {
      if (element["name"] === value) {
        obj = element;
        return;
      }
    });
    let keys = ["DevOps", "Development", "Security", "Testing"];
    let values = [];
    values.push(obj["DevOps"]);
    values.push(obj["Development"]);
    values.push(obj["Security"]);
    values.push(obj["Testing"]);

    let chartOptions = {
      series: [
        {
          name: "Maturity Score",
          data: values,
        },
      ],
      appendPadding: 12,
      options: {
        chart: {
          type: "radar",
          height: "350px",
        },
        title: {
          // text: "chart basic",
        },
        xaxis: {
          categories: keys,
        },
      },
    };
    setChartOptions(chartOptions);
  };
  let selectedOption;

  const asyncFetch = async () => {
    setLoading(true);
    let final_array = [];
    let projects = [];
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project/getallprojectbyid";
    await axios
      .get(url, { params })
      .then((res) => {
        console.log("response of get all projects", res.data);
        let values = [];
        let keys = ["DevOps", "Development", "Security", "Testing"];
        values.push(res.data[0]["DevOps"]);
        values.push(res.data[0]["Development"]);
        values.push(res.data[0]["Security"]);
        values.push(res.data[0]["Testing"]);
        let c_options = {
          series: [
            {
              name: "Maturity Score",
              data: values,
            },
          ],
          options: {
            chart: {
              type: "radar",
              height: "400px",
            },
            title: {
              // text: "chart basic",
            },
            xaxis: {
              categories: keys,
            },
          },
        };
        res.data.forEach((element) => {
          let obj = {};
          obj["name"] = element["name"];
          obj["DevOps"] = element["DevOps"];
          obj["Development"] = element["Development"];
          obj["Security"] = element["Security"];
          obj["Testing"] = element["Testing"];
          final_array.push(obj);
          projects.push(element["name"]);
        });
        console.log("final array =======", projects);
        setData(final_array);
        setChartOptions(c_options);
        setProjecttData(projects);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
      });
  };

  return (
    <Card title="Select project to view Spider charts">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div>
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return (
                <div style={{ height: "400px" }}>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    defaultValue={projectData[0]}
                    style={{ width: "180px" }}
                  >
                    {projectData.map((option, index) => (
                      <Option key={index} value={option}></Option>
                    ))}
                  </Select>

                  <ReactApexChart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type="radar"
                    width={500}
                    height={400}
                  />
                </div>
              );
            }
          })()}
        </div>
      )}
    </Card>
  );
}
