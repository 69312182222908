import React, { useState, useEffect } from "react";

//import { Bar } from "react-chartjs-2";
import { Card, Spin, Empty } from "antd";
import axios from "axios";
import { Column, Bar } from "@ant-design/plots";

export default function Barchart(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    project_id: props.projectId,
    module_id: props.moduleId,
  };
  console.log("params, pa", params);
  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    setLoading(true);
    let final_array = [];
    const url =
      " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/items-implemented";
    await axios
      .get(url, { params })
      .then((res) => {
        console.log("response from items implemented", res);
        // setData(res.data);

        res.data.forEach((element) => {
          let totalObj = {};
          let implementedObj = {};
          let notImplementedObj = {};
          // console.log("data inside for each", element);
          totalObj["process"] = element["Process"];
          totalObj["type"] = "total";
          totalObj["value"] = Number(element["TotalItems"]);
          final_array.push(totalObj);
          implementedObj["process"] = element["Process"];
          implementedObj["type"] = "implemented";
          implementedObj["value"] = Number(element["Implemented"]);
          final_array.push(implementedObj);
          notImplementedObj["process"] = element["Process"];
          notImplementedObj["type"] = "not_implemented";
          notImplementedObj["value"] =
            element["TotalItems"] - element["Implemented"];
          final_array.push(notImplementedObj);
        });

        let x = final_array.filter((item) => item["process"] !== "Overall");
        // console.log("final array for antd bar chart", x);
        setData(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
      });
  };

  const config = {
    data,
    xField: "process",
    yField: "value",
    seriesField: "type",
    isGroup: true,
    maxColumnWidth: 25,
    columnStyle: {
      radius: [0, 0, 0, 0],
    },
    dodgePadding: data.length < 5 ? 5 : 0,
  };

  return (
    <Card title="Number of items implemented">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div>
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return <Column {...config} />;
            }
          })()}
        </div>
      )}
    </Card>
  );
}
