import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Card, Empty, Spin } from "antd";
import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export default function Pichart({ organizationId }) {
  const [pieData, setPieData] = useState([]);
  const [pieLabel, setPieLable] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    organization_id: organizationId,
  };

  useEffect(() => {
    setLoading(true);
    const url =
      " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/org-overall-score";
    axios
      .get(url, { params })
      .then((res) => {
        let pieChartData = [];
        let pieLabel = [];
        let total_percentage = 0;
        console.log("Responsee===", res.data);
        let data = res.data;
        data.forEach((element) => {
          pieChartData.push(element.percentage.toFixed(2));
          pieLabel.push(element.name);
          total_percentage += element.percentage;
        });
        pieLabel.push("Not Implemented");
        let not_implement = 100 - total_percentage;
        not_implement = not_implement.toFixed(2);
        pieChartData.push(not_implement);
        setPieData(pieChartData);
        setPieLable(pieLabel);
        setLoading(false);
      })
      .catch((error) => {
        console.log("errro-------", error);
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.dataset.data[context.dataIndex] + "%";
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: "right",
        labels: {
          padding: 30,
        },
        padding: {
          bottom: 20,
        },
      },
      title: {
        display: true,
        text: "Overall Score",
        font: {
          size: 20,
        },
      },
    },
  };

  const data = {
    labels: pieLabel,
    datasets: [
      {
        height: "500px",
        data: pieData,
        borderWidth: 1,
      },
    ],
  };
  return (
    <Card>
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div style={{ height: "460px" }}>
          {(() => {
            if (pieData.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return (
                <Pie
                  data={data}
                  options={options}
                  width="500px"
                  height="500px"
                />
              );
            }
          })()}
        </div>
      )}
    </Card>
  );
}
