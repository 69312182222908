import {
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Card,
  message,
  Row,
  Col,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import axios from "axios";
import "antd/dist/antd.min.css";
import { Box } from "@mui/system";
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

export default function User(props) {
  const [projectId, setProjectId] = useState([]);
  const [roleId, setRoleId] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [userUpdateId, setUserUpdateId] = useState();
  const [userDetails, setUserDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    accesslevel: "",
    organization: "",
    project: "",
    username: "",
    password: "",
  });

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    getOrgList();
    getAllUserList();
    getAllRoleData();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const renderSelectedProjects = () => {
    return (
      <div>
        {selectedProjects.map((projectId) => {
          const project = projectList.find((p) => p.project_id === projectId);
          return (
            <Chip
              key={project.project_id}
              label={project.name}
              onDelete={() => {
                const newSelectedProjects = selectedProjects.filter(
                  (id) => id !== projectId
                );
                setSelectedProjects(newSelectedProjects);
              }}
            />
          );
        })}
      </div>
    );
  };

  const handleProjectChange = (event) => {
    setSelectedProjects(event.target.value);
  };

  const getAllRoleData = () => {
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/role/get-all-roles";
    axios
      .get(url)
      .then((res) => {
        setRoleData(res.data.body);
      })
      .catch((error) => {
        console.log("error from getallroleapi", error);
      });
  };

  const onDeleteUser = ({ user_id }) => {
    setDeleteLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/delete-user";
    axios
      .delete(url, { params: { user_id: user_id } })
      .then((res) => {
        setDeleteLoading(false);
        if (res.data[0] === "User deleted successfully !") {
          message.success(res.data[0]);
        } else {
          message.error(res.data[0]);
        }
        getAllUserList();
      })
      .catch((err) => {
        console.log("error from delete user=======>", err);
      });
  };

  const cancelCallOnDelete = (value) => {
    console.log("cancel successfully", value);
  };

  const showViewModal = (data) => {
    setUserDetails({
      firstname: data.first_name,
      lastname: data.last_name,
      email: data.email,
      phone: data.mobile,
      accesslevel: data.role ? data.role.name : "NA",
      organization: data.organization ? data.organization.name : "NA",
      username: data.user_name,
    });
    setOpenView(true);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOkEdit = () => {
    setUpdateLoading(true);
    const data = {
      user_id: userUpdateId,
      first_name: userDetails.firstname,
      last_name: userDetails.lastname,
      role_id: roleId,
      organization_id: organizationId,
      mobile: userDetails.phone,
    };
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/update-user";
    axios
      .put(url, data)
      .then((res) => {
        setUpdateLoading(false);
        if (res.data.statusCode === 200) {
          setOpenEdit(false);
          message.success("user updated successfully");

          getAllUserList();
          setUserDetails({
            firstname: "",
            lastname: "",
            phone: "",
          });
        } else if (res.data.statusCode === 400) {
          const response = JSON.parse(res.data.body);
          message.error(response.original.detail, 4);
        }
      })
      .catch((err) => {
        console.log("error from user update", err);
      });
  };

  const handleCancelEdit = () => {
    setOpenEdit(false);
  };

  const handleCancelView = () => {
    setOpenView(false);
  };

  const getAllUserList = () => {
    setUserLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/get-all-users";
    axios
      .get(url)
      .then((res) => {
        console.log("all users======", res);
        setUserList(res.data.body);
        setUserLoading(false);
      })
      .catch((err) => {
        console.log("error from getAllUserList====>", err);
      });
  };

  const getOrgList = () => {
    const token = localStorage.getItem("token");
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization/get-all-organizations";
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        setOrgList(data);
      })
      .catch((err) => {
        console.log("error from getOrgList======>", err);
      });
  };

  const getProjectListByOrganizationId = (orgId) => {
    const params = {
      organization_id: orgId,
    };
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project/getallprojectbyid";
    axios
      .get(url, { params })
      .then((res) => {
        setProjectList(res.data);
      })
      .catch((err) => {
        console.log("error from getProjectList======>", err);
      });
  };

  const showEditModal = (data) => {
    setUserUpdateId(data.user_id);
    if (data.organization) {
      setOrganizationId(data.organization.organization_id);
    }
    if (data.role) {
      setRoleId(data.role.role_id);
    }
    setUserDetails({
      firstname: data.first_name,
      lastname: data.last_name,
      accesslevel: data.role ? data.role.name : null,
      organization: data.organization ? data.organization.name : null,
      phone: data.mobile,
    });
    setOpenEdit(true);
  };

  const handleCancel = () => {
    setUserDetails({
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      username: "",
      password: "",
    });
    setOpen(false);
  };

  const handleEditCancel = () => {
    setOpenEdit(false);
  };

  const columns1 = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      align: "left",
      width: 120,
      render: (_, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      width: 120,
    },
    {
      title: "Role Name",
      dataIndex: "role_name",
      align: "center",
      width: 120,
      render: (_, record) =>
        record?.role?.name ? record?.role?.name : <>NA</>,
    },
    {
      title: "Organization Name",
      dataIndex: "organization_name",
      align: "center",
      width: 120,
      render: (_, record) =>
        record?.organization?.name ? record?.organization?.name : <>NA</>,
    },
    {
      title: "Actions",
      align: "center",
      dataIndex: "operation",
      Key: "operation",
      width: 120,
      render: (_, data) => (
        <Space size="middle">
          <Tooltip title="Edit User Details">
            <EditTwoTone
              twoToneColor="#5046e5"
              style={{ fontSize: "18px" }}
              onClick={() => showEditModal(data)}
            />
          </Tooltip>
          <Tooltip title="View User">
            <EyeTwoTone
              twoToneColor="#5046e5"
              style={{ fontSize: "20px" }}
              onClick={() => {
                showViewModal(data);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete User">
            <Popconfirm
              placement="left"
              title="Are you sure, you want to delete the user?"
              onConfirm={() => {
                onDeleteUser(data);
              }}
              okButtonProps={{
                loading: deleteLoading,
              }}
              onCancel={() => cancelCallOnDelete(data)}
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              <DeleteTwoTone
                twoToneColor="#d91d0f"
                style={{
                  fontSize: "17px",
                }}
                size="small"
                shape="circle"
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  function SubmitButton() {
    if (
      userDetails.firstname.trim().length &&
      userDetails.lastname.trim().length &&
      userDetails.email.trim().length &&
      userDetails.username.trim().length &&
      userDetails.password.trim().length &&
      userDetails.phone.trim().length &&
      roleId &&
      organizationId &&
      projectId
    ) {
      return (
        <Button type="primary" onClick={handleSubmit} loading={submitLoading}>
          Submit
        </Button>
      );
    } else {
      return <Button disabled>Submit</Button>;
    }
  }

  const handleSubmit = () => {
    setSubmitLoading(true);
    const creation_data = {
      first_name: userDetails.firstname,
      last_name: userDetails.lastname,
      email: userDetails.email,
      user_name: userDetails.username,
      password: userDetails.password,
      mobile: userDetails.phone,
      organization_id: organizationId,
      role_id: roleId,
      projects: selectedProjects,
    };
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users";
    axios
      .post(url, creation_data)
      .then((res) => {
        if (res.data.statusCode === 201) {
          setSubmitLoading(false);
          message.success("user created successfully", 2);
          setSubmitLoading(false);
          setUserDetails({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            username: "",
            password: "",
            roleId: "",
            organizationId: "",
            projectId: "",
          });
          getAllUserList();
          setOpen(false);
        } else if (res.data.statusCode === 400) {
          message.error(res.data.body);
        } else {
          message.error(res.data.body, 2);
        }
      })
      .catch((err) => {
        console.log("error during user creation", err);
      });
  };

  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">User List</div>
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <div
                style={{
                  position: "absolute",
                  right: "16px",
                  margin: "-23px 16px 0 0",
                }}
              >
                <Tooltip title="create user">
                  <Button
                    className="btn-admin btn-subCategory event-button float-end"
                    type="primary"
                    style={{ background: "#5046E5" }}
                    onClick={showModal}
                  >
                    Create
                  </Button>
                </Tooltip>
                <Modal
                  open={open}
                  title="User Details"
                  onCancel={handleCancel}
                  footer={[]}
                >
                  <Card>
                    <FormControl>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="firstname"
                          label="Firstname"
                          variant="outlined"
                          size="small"
                          onChange={handleChange}
                          name="firstname"
                          required
                          value={userDetails.firstname}
                        />
                        <TextField
                          id="lastname"
                          label="Lastname"
                          variant="outlined"
                          size="small"
                          value={userDetails.lastname}
                          onChange={handleChange}
                          name="lastname"
                          required
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          size="small"
                          value={userDetails.email}
                          onChange={handleChange}
                          name="email"
                          required
                        />
                        <TextField
                          id="phone"
                          label="Phone"
                          variant="outlined"
                          size="small"
                          value={userDetails.phone}
                          onChange={handleChange}
                          name="phone"
                          required
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "52ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          value={roleId}
                          onChange={(e) => setRoleId(e.target.value)}
                          name="accesslevel"
                          select // tell TextField to render select
                          label="Access Level"
                          size="small"
                          required
                        >
                          {roleData.map((data, index) => (
                            <MenuItem key={data.role_id} value={data.role_id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "52ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          value={organizationId}
                          onChange={(e) => {
                            setOrganizationId(e.target.value);
                            getProjectListByOrganizationId(e.target.value);
                          }}
                          select // tell TextField to render select
                          label="Organization"
                          size="small"
                          name="organization"
                          required
                        >
                          {orgList.map((data, index) => (
                            <MenuItem
                              key={data.organization_id}
                              value={data.organization_id}
                            >
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      {roleId !== 1 ? (
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": {
                              m: 1,
                              width: "52ch",
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <FormControl fullWidth>
                            <InputLabel id="demo-mutiple-checkbox-label">
                              Projects
                            </InputLabel>
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              value={selectedProjects}
                              onChange={handleProjectChange}
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  label="Projects"
                                />
                              }
                              MenuProps={MenuProps}
                              renderValue={() => renderSelectedProjects()}
                            >
                              {projectList.map((project) => (
                                <MenuItem
                                  key={project.project_id}
                                  value={project.project_id}
                                >
                                  <Checkbox
                                    checked={
                                      selectedProjects.indexOf(
                                        project.project_id
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={project.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="username"
                          label="Username"
                          variant="outlined"
                          size="small"
                          onChange={handleChange}
                          name="username"
                          required
                          value={userDetails.username}
                        />
                        <TextField
                          id="password"
                          type="password"
                          label="Password"
                          variant="outlined"
                          size="small"
                          value={userDetails.password}
                          onChange={handleChange}
                          name="password"
                          required
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "52ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <SubmitButton />
                      </Box>
                    </FormControl>
                  </Card>
                </Modal>
                <Modal
                  open={openEdit}
                  title="Update user details"
                  onCancel={handleEditCancel}
                  footer={[
                    <Button
                      key="back"
                      onClick={handleCancelEdit}
                      type="primary"
                    >
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      className="btn-admin btn-subCategory event-button float-end"
                      style={{ background: "#5046E5" }}
                      onClick={handleOkEdit}
                      loading={updateLoading}
                    >
                      Update
                    </Button>,
                  ]}
                >
                  <Card>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="firstname"
                        label="Firstname"
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                        name="firstname"
                        required
                        value={userDetails.firstname}
                      />
                      <TextField
                        id="lastname"
                        label="Lastname"
                        variant="outlined"
                        size="small"
                        value={userDetails.lastname}
                        onChange={handleChange}
                        name="lastname"
                        required
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "52ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        value={roleId}
                        onChange={(e) => setRoleId(e.target.value)}
                        name="accesslevel"
                        select // tell TextField to render select
                        label="Access Level"
                        size="small"
                        required
                      >
                        {roleData.map((data, index) => (
                          <MenuItem key={data.role_id} value={data.role_id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "52ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        value={organizationId}
                        name="organization"
                        onChange={(e) => setOrganizationId(e.target.value)}
                        select // tell TextField to render select
                        label="Organization"
                        size="small"
                        required
                      >
                        {orgList.map((data, index) => (
                          <MenuItem
                            key={data.organization_id}
                            value={data.organization_id}
                          >
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "52ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        size="small"
                        value={userDetails.phone}
                        onChange={handleChange}
                        name="phone"
                        required
                      />
                    </Box>
                  </Card>
                </Modal>
                <Modal
                  open={openView}
                  title="User Details"
                  onCancel={handleCancelView}
                  footer={null}
                >
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="firstname"
                      label="Firstname"
                      variant="outlined"
                      size="small"
                      name="firstname"
                      value={userDetails.firstname}
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      id="lastname"
                      label="Lastname"
                      variant="outlined"
                      size="small"
                      value={userDetails.lastname}
                      name="lastname"
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="phone"
                      label="mobile"
                      variant="outlined"
                      size="small"
                      name="phone"
                      value={userDetails.phone}
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      id="email"
                      label="email"
                      variant="outlined"
                      size="small"
                      value={userDetails.email}
                      name="email"
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="accesslevl"
                      label="Accesslevel"
                      variant="outlined"
                      size="small"
                      name="accesslevel"
                      value={userDetails.accesslevel}
                      inputProps={{ readOnly: true }}
                    />
                    <TextField
                      id="organization"
                      label="Organization"
                      variant="outlined"
                      size="small"
                      value={userDetails.organization}
                      name="organization"
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "52ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="username"
                      label="username"
                      variant="outlined"
                      size="small"
                      value={userDetails.username}
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                </Modal>
              </div>
            </Row>
            <div className="my-4 cloud-charts">
              <div className="card mt-5">
                <div className="card-body p-5">
                  <Table
                    columns={columns1}
                    dataSource={userList}
                    loading={userLoading}
                    pagination={{ pageSize: 10 }}
                    scroll={{ y: 450 }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
