import {
  DeleteTwoTone,
  EyeTwoTone,
  PieChartTwoTone,
  QuestionCircleOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  message,
  Input,
  Form,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CreateOrganization from "./CreateOrganization";
import { routes } from "../../util/constants";
import axios from "axios";

export default function Organization() {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [projectObject, setProjectObject] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgName, setOrgName] = useState("");
  const [navigateToProject, setNavigateToProject] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editRecord, setEditRecord] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const stateData = {
    orgId: orgId,
    orgName: orgName,
    projectObject: projectObject,
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(
      "CognitoIdentityServiceProvider.5aikoaqjg4necgijavjq6ttk36.azuread_shruti.goyal@yash.com.accessToken"
    );
    console.log("accesstoken", accessToken);
    setLoading(true);
    getAllOrganizations();
  }, []);

  const getAllOrganizations = async () => {
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN") {
      setIsAdmin(true);
    }
    setLoading(true);
    const token = localStorage.getItem("token");
    let response = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization/get-all-organizations`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("Get all org response", response.data);
    setOrganizations(response.data);
    setLoading(false);
  };

  const handleDeleteOrganization = async (data) => {
    let response = await axios
      .delete(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization/delete-organization?organization_id=${data.organization_id}`
      )
      .then((response) => {
        console.log("Delete Response", response);
        if (response.status === 200) {
          message.success("Organization deleted successfully !!");
          getAllOrganizations();
        }
      })
      .catch((err) => {
        console.log("Error===>", err);
      });
  };

  const showEditModal = (record) => {
    setOrganizationName(record.name);
    setOrgDescription(record.description);
    setEditModal(true);
    setEditRecord(record);
  };

  const handleEditModalCancel = () => {
    setEditModal(false);
    setUpdateLoading(false);
  };

  const onChangeOrgName = (event) => {
    setOrganizationName(event.target.value);
  };

  const onChangeOrgDesc = (event) => {
    setOrgDescription(event.target.value);
  };

  const onSubmit = async (event) => {
    setUpdateLoading(true);
    let orgObject = {
      organization_id: editRecord.organization_id,
      name: event.organizationName,
      description: event.orgDescription,
    };
    let response = await axios
      .put(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization/update-organization`,
        orgObject
      )
      .then((response) => {
        console.log("Update response", response.data);
        if (response.data.statusCode === 200) {
          setUpdateLoading(false);
          getAllOrganizations();
          handleEditModalCancel();
          message.success("Organization updated successfully!!");
        }
      })
      .catch((err) => {
        console.log("Error in Edit==>", err);
      });
  };

  const navigateToCharts = (record) => {
    navigate(routes.ORGCHARTS, { state: record });
  };

  const columns = [
    {
      title: "#",
      key: "",
      dataIndex: "",
      width: 50,
      render: (record, value, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Organization Name",
      key: "",
      dataIndex: "",
      width: 120,
      render: (record) => {
        return (
          <Typography.Text
            onClick={(event, id) =>
              navigateToProjects(
                record.projectscount,
                record.organization_id,
                record.name
              )
            }
            className="underline-effect"
          >
            {record.name}
          </Typography.Text>
        );
      },
    },

    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: 120,
    },
    {
      title: "Projects",
      key: "projectscount",
      dataIndex: "projectscount",
      width: 100,
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      width: 100,
      render: (record) => {
        return moment(record.createdAt).format("MMMM Do YYYY");
      },
    },
    {
      title: "Actions",
      key: "",
      dataIndex: "",
      width: 100,
      render: (record) => {
        return (
          <>
            <Space size="middle">
              <Tooltip title="View Charts">
                <PieChartTwoTone
                  twoToneColor="#5046e5"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    navigateToCharts(record);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete Organization">
                <Popconfirm
                  title="Are you sure, you want to delete the organization?"
                  onConfirm={() => {
                    handleDeleteOrganization(record);
                  }}
                  onCancel={() => getAllOrganizations()}
                  icon={<QuestionCircleOutlined style={{ colour: "red" }} />}
                >
                  {isAdmin ? (
                    <DeleteTwoTone
                      twoToneColor="red"
                      style={{ fontSize: "17px" }}
                    />
                  ) : (
                    <></>
                  )}
                </Popconfirm>
              </Tooltip>
              <Tooltip title="Edit Organization">
                {isAdmin ? (
                  <EditTwoTone
                    twoToneColor="#0089eb"
                    style={{ fontSize: "17px" }}
                    onClick={() => {
                      showEditModal(record);
                    }}
                  />
                ) : (
                  <></>
                )}
              </Tooltip>
            </Space>
          </>
        );
      },
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const navigateToProjects = (event, id, name) => {
    setProjectObject(event);
    setOrgName(name);
    setOrgId(id);
    setNavigateToProject(true);
  };
  return (
    <>
      {navigateToProject ? (
        <Navigate to={routes.PROJECTS} state={stateData} />
      ) : (
        ""
      )}
      <Row>
        <div style={{ position: "absolute", top: "-30px", right: "16px" }}>
          {isAdmin ? (
            <Button
              style={{ marginLeft: "auto" }}
              className="ok-modal "
              onClick={showModal}
            >
              Create
            </Button>
          ) : (
            <></>
          )}

          <Modal
            open={visible}
            title="Create Organization"
            onCancel={handleModalCancel}
            destroyOnClose={true}
            style={{ top: 20 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            width={500}
          >
            <CreateOrganization
              getAllOrganizations={getAllOrganizations}
              handleModalCancel={handleModalCancel}
            />
          </Modal>
        </div>
      </Row>
      <div className="my-4 cloud-charts">
        <div className="card mt-5">
          <div className="card-body p-5">
            <Table
              columns={columns}
              dataSource={organizations}
              loading={loading}
              pagination={{ pageSize: 10 }}
              scroll={{ y: 450 }}
            />
          </div>
        </div>
      </div>
      <Modal
        open={editModal}
        title="Edit Organization"
        onCancel={handleEditModalCancel}
        destroyOnClose={true}
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={600}
      >
        <div>
          <Form onFinish={onSubmit} name="organization">
            <Form.Item name="organizationName">
              <Input
                placeholder="Enter organization name"
                name="organizationName"
                defaultValue={editRecord.name}
                onChange={onChangeOrgName}
              />
            </Form.Item>
            <Form.Item name="orgDescription">
              <Input
                placeholder="Enter description"
                name="orgDescription"
                defaultValue={editRecord.description}
                onChange={onChangeOrgDesc}
              />
            </Form.Item>
            <Form.Item className="float-end f-right orgbutton">
              <Button
                className="close-modal me-3"
                onClick={handleEditModalCancel}
              >
                Close
              </Button>
              &nbsp;
              <Button
                className="ok-modal"
                type="primary"
                htmlType="submit"
                loading={updateLoading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
