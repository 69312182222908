import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { routes } from "../../util/constants";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@mui/icons-material";
import { Table } from "antd";

export default function ProjectModule(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/module/getall-module"
      );
      console.log("Response dataaa of modules==>", response.data.body);
      setData(response.data.body);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data from backend:", error);
    }
  };

  const handleClickCharts = (module) => {
    const module_name = module.name;
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN" || access_level === "ORGANIZATION") {
      navigate(routes.PROJECTCHARTS, {
        state: {
          project_id: props.props.location.state.project_id,
          module_name: module_name,
          module_id: module.module_id,
        },
      });
    } else {
      navigate(routes.PROJECTCHARTS, {
        state: {
          project_id: props.props.location.state.project_id,
          module_name: module_name,
          module_id: module.module_id,
        },
      });
    }
  };

  const navigateToReports = (value) => {
    navigate(routes.REPORTS, {
      state: {
        project_id: props.props.location.state.project_id,
        module_id: value.module_id,
        module_name: value.name,
      },
    });
  };

  const downloadImage = async (record) => {
    console.log("record inside download image", record);

    const reportName = record.report_name;
    /*  const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/report";
    const params = {
      reportName: reportName,
    }; */

    const resp = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/pre-signed-url/report?reportName=${reportName}`
    );

    /* console.log("Response Data =====>",resp);

    const downloadUrl = record.document_url; */

    window.open(`${resp.data}`);
  };
  const handleGenerateReport = async (value) => {
    setOpenModal(true);
    setTableLoading(true);

    const project_id = props.props.location.state.project_id;
    const module_id = value.module_id;

    console.log("Value =======>", value);
    // console.log("value inside handle generate report", project_id, module_id);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/report";
    const params = {
      projectId: project_id,
      moduleId: module_id,
    };
    console.log("params====", params);
    const resp = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/report?projectId=${project_id}&moduleId=${module_id}`
    );
    console.log("response from get all reports", resp.data);
    setReports(resp.data);
    setTableLoading(false);
    console.log("response from get reports", resp);
  };

  const handleModalCancel = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      title: "Report Name",
      dataIndex: "report_name",
      key: "name",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <Button onClick={() => downloadImage(record)}>
            <DownloadOutlined />
          </Button>
        );
      },
    },
  ];

  const handleClick = (a, e, value) => {
    const access_level = localStorage.getItem("access_level");

    if (access_level === "ORGANIZATION" || access_level === "ADMIN") {
      navigate(routes.PROJECTDATA, {
        state: {
          data: a,
          moduleName: a.name,
          project_id: props.props.location.state.project_id,
          module_id: a.module_id,
        },
      });
    } else {
      navigate(routes.PROLEVELPROJECTDATA, {
        state: {
          data: a,
          moduleName: a.name,
          project_id: props.props.location.state.project_id,
          module_id: module.module_id,
        },
      });
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }

  return (
    <>
      <div className="my-4 cloud-charts">
        <div className="card mt-5">
          <div className="card-body p-5">
            {loading === true ? (
              <div className="TabsLoading">
                <Spin />
              </div>
            ) : (
              <>
                {data.length > 0 ? (
                  <Grid container rowSpacing={1}>
                    {data.map((value, index) => (
                      <Grid item xs={6} md={6} key={index}>
                        <Card
                          variant="outlined"
                          sx={{
                            m: 2,
                            boxShadow: "5px 5px #F1F8FD",
                          }}
                        >
                          <CardContent>
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                {...stringAvatar(value.name)}
                                variant="circular"
                              />
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                  color: "#5046e5",
                                  fontWeight: "bold",
                                }}
                              >
                                {value.name}
                              </Typography>
                            </Stack>
                          </CardContent>
                          <CardActions>
                            <Row
                              gutter={[8, 8]}
                              style={{ alignItems: "center" }}
                            >
                              {/* <Row> */}
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={10}
                                xxl={10}
                              >
                                <Button
                                  size="medium"
                                  sx={{
                                    border: "solid 1px #007CB9",
                                    ml: 1,
                                  }}
                                  onClick={(a, e) => {
                                    handleClick(value);
                                  }}
                                >
                                  View Project Data
                                </Button>
                              </Col>

                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                              >
                                <Button
                                  size="medium"
                                  sx={{
                                    border: "solid 1px #007CB9",
                                    ml: 1,
                                  }}
                                  onClick={(a, e) => {
                                    handleClickCharts(value);
                                  }}
                                >
                                  View Charts
                                </Button>
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={6}
                                xxl={6}
                              >
                                <Button
                                  size="medium"
                                  sx={{
                                    border: "solid 1px #007CB9",
                                    ml: 1,
                                  }}
                                  onClick={(a, e) => {
                                    // handleGenerateReport(value);
                                    navigateToReports(value);
                                  }}
                                >
                                  View Reports
                                </Button>
                              </Col>
                            </Row>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
                <Modal
                  open={openModal}
                  width={500}
                  onCancel={handleModalCancel}
                  title="Reports"
                  style={{ top: 75 }}
                  okButtonProps={{ hidden: true }}
                  cancelButtonProps={{ hidden: true }}
                  destroyOnClose={true}
                >
                  <Table
                    columns={columns}
                    loading={tableLoading}
                    dataSource={reports}
                  />
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
