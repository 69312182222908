import React, { useState, useEffect } from "react";
import {
  Col,
  Dropdown,
  Row,
  Space,
  Table,
  Tooltip,
  message,
  Upload,
  Typography,
  Radio,
  Card,
  InputNumber,
} from "antd";
import {
  DownOutlined,
  RightOutlined,
  PlusOutlined,
  ProjectOutlined,
  ApartmentOutlined,
  TrophyOutlined,
  ReadOutlined,
  SnippetsOutlined,
  QuestionCircleOutlined,
  BulbOutlined,
  MinusCircleOutlined,
  InboxOutlined,
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Breadcrumb from "../BreadCrumbs";
import { Button, Modal, Form, Input, Select } from "antd";
import axios from "axios";
import { HideImage } from "@mui/icons-material";
import TextArea from "antd/lib/input/TextArea";

export default function MasterData(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredRowRecord, setHoveredRowRecord] = useState({});
  const [itemType, setItemType] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [categoryChildRecord, setCategoryChildRecord] = useState({});
  const [multipleChildRecord, setMultipleChildRecord] = useState({});
  const [subCategoryChildRecord, setSubCategoryChildRecord] = useState({});
  const [subCategoryParentRecord, setSubCategoryParentRecord] = useState({});
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [questionArray, setQuestionArray] = useState([]);
  const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [submitQuestionLoading, setSubmitQuestionLoading] = useState(false);
  const [answerType, setAnswerType] = useState("none");
  const [answerTypeForCustomQuestion, setAnswerTypeForCustomQuestion] =
    useState("none");

  const { Option } = Select;
  const { Dragger } = Upload;
  const { Group } = Radio;
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const propsForFileUpload = {
    name: "file",
    multiple: false,
    accept: "text/csv",
    maxCount: 1,
    beforeUpload: (file) => {
      callBeforeUpload(file);

      return false; // Prevent automatic file upload
    },
    fileList: isFileUploadModalOpen ? fileList : [],
    onRemove: (file) => {
      setFileList([]);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log("filelissttttttttt", info.fileList);
      }
      if (status === "done") {
        console.log("length of file list", info.fileList);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(info.fileList);
    },

    onDrop(e) {
      console.log("#Dropped files", e.dataTransfer.files);
    },
  };

  const base64ToBlob = (data) => {
    const sliceSize = 1024;
    const byteCharacters = atob(data);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i += sliceSize) {
      const slice = byteCharacters.slice(i, i + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let x = 0; x < slice.length; x++) {
        byteNumbers[x] = slice.charCodeAt(x);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: "text/csv" });
  };
  const downloadTemplate = () => {
    setFileDownloadLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/pre-signed-url/get-template";
    axios
      .get(url)
      .then((res) => {
        const base64Url = res.data.body.url;
        console.log("dummyyyy dataaaaaa", base64Url);
        const blob = base64ToBlob(base64Url);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        setFileDownloadLoading(false);
        link.href = url;
        link.download = "template.csv";
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/master_data/getall"
      );
      const data = await response.json();
      console.log("body==", data.body);
      setLoading(false);
      setData(data.body);
      console.log("data== master", data);
    } catch (error) {
      console.error("Error fetching data from backend:", error);
    }
  };

  const callBeforeUpload = async (file) => {
    setFileToUpload(file);
  };

  const handleUpload = async () => {
    if (
      !propsForFileUpload.fileList ||
      propsForFileUpload.fileList.length === 0
    ) {
      message.error("No File Selected");
      return;
    }
    console.log("Entered handle Upload", propsForFileUpload.fileList);
    setFileUploadLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/pre-signed-url";

    var user = JSON.parse(localStorage.getItem("userData"));
    const params = {
      name: fileToUpload.name,
      module_id: selectedRecord.module_id,
      uploadedBy: user.email,
      request_type: "PUT",
    };

    var result = "";
    axios
      .post(url, params)
      .then(async (res) => {
        console.log("pre signed url==========", res.data.body);

        result = res.data.body;
        console.log("result after seting url=================", result);
        if (result === "") {
          message.error("Pre-signed URL not available.");
          return;
        }
        try {
          console.log("pre signed================", result);
          const signedRequestResponse = await fetch(result, {
            method: "PUT",
            body: fileToUpload,
          });
          console.log("signed req res", signedRequestResponse);

          if (signedRequestResponse.ok) {
            // message.success(`${fileToUpload.name} file uploaded successfully.`);
            const url_2 =
              "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/pre-signed-url/check-file";
            const params_2 = {
              module_id: selectedRecord.module_id,
              message:
                "Your maturity score has been dropped. There are new items available, please answer the additional questions.",

              file_name: fileToUpload.name,
            };
            axios
              .post(url_2, params_2)
              .then((res) => {
                console.log("response after confirmation", res);
                fetchData();
                message.success(
                  `${fileToUpload.name} file uploaded successfully.`
                );
                setFileUploadLoading(false);
                handleUploadFileModalCancel();
              })
              .catch((err) => {
                console.log("error afer confirmation", err);
              });
          } else {
            message.error(`${fileToUpload.name} file upload failed.`);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          message.error("An error occurred while uploading the file.");
        }
        //setFileUploadLoading(false);

        //handleUploadFileModalCancel();
      })
      .catch((err) => {
        console.log("error------", err);
      });
  };

  const showModal = (record) => {
    setIsModalOpen(true);
    if (record.hasOwnProperty("questionChild")) {
      console.log("inside if of show modal category", categoryChildRecord);
      setMultipleChildRecord(record);
      console.log("inside show modal of sub category==", categoryChildRecord);
      setSelectedRecord(categoryChildRecord);
    }
    if (
      record.hasOwnProperty("subCategoryItemChild") ||
      record.hasOwnProperty("subCategoryQuestionChild")
    ) {
      console.log("inside if of sub category child", subCategoryParentRecord);
      setSubCategoryChildRecord(record);
      setSelectedRecord(subCategoryParentRecord);
    } else setSelectedRecord(record);
    console.log("show record inside modal", record);
    if (record.hasOwnProperty("sub_category_item_")) setItemType("Question");

    if (
      record.hasOwnProperty("module_id") &&
      !record.hasOwnProperty("process_id")
    )
      setItemType("Process");
    if (
      record.hasOwnProperty("process_id") &&
      !record.hasOwnProperty("stage_id")
    )
      setItemType("Stage");
    if (
      record.hasOwnProperty("stage_id") &&
      !record.hasOwnProperty("category_id")
    )
      setItemType("Category");
    if (
      record.hasOwnProperty("category_id") &&
      !record.hasOwnProperty("sub_category_id")
    ) {
      setItemType("Sub Category");
    }
    if (record.hasOwnProperty("subCategoryChild")) {
      console.log("set item type======", selectedRecord);
      setItemType("Sub Category");
      setSelectedRecord(categoryChildRecord);
      setMultipleChildRecord(record);
    }
    if (
      record.hasOwnProperty("questionChild") ||
      record.hasOwnProperty("subCategoryQuestionChild")
    )
      setItemType("Question");
    if (record.hasOwnProperty("subCategoryItemChild"))
      setItemType("Sub Category Item");

    if (
      record.hasOwnProperty(
        "sub_category_id" && !record.hasOwnProperty("question_id")
      )
    )
      setItemType("Question");
    if (
      record.hasOwnProperty("sub_category_id") &&
      record.questions.length === 2
    )
      setItemType("Sub Category Item");
  };

  const handleOk = () => {
    setLoading(true);
    setLoading(false);
    setIsModalOpen(false);
  };

  const showQuestionModal = (record, subCategory = {}) => {
    setIsQuestionModalOpen(true);
    if (record.hasOwnProperty("questionChild")) {
      console.log("setting category as selected record", categoryChildRecord);
      setSelectedRecord(categoryChildRecord);
    }
    if (record.hasOwnProperty("subCategoryQuestionChild"))
      setSelectedRecord(subCategoryParentRecord);
    else {
      setSelectedRecord(record);
      setSubCategoryParentRecord(subCategory);
      console.log("Sub categoryyyy inside showwww", subCategory);
    }
    setItemType("Question(s)");
  };

  const showFileUploadModal = (record) => {
    setSelectedRecord(record);
    setFileUploadModalOpen(true);
  };

  const handleUploadFileModalOk = () => {
    setFileUploadModalOpen(false);
  };

  const handleUploadFileModalCancel = () => {
    setFileList([]);
    setFileUploadModalOpen(false);
  };

  const handleQuestionModalOk = () => {
    setIsQuestionModalOpen(false);
  };

  const handleQuestionModalCancel = () => {
    // form.resetFields();
    setIsQuestionModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCategoryChildRecord({});
    setSubCategoryChildRecord({});
    setSelectedRecord({});
  };

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeDesc = (event) => {
    setDescription(event.target.value);
  };

  const handleRowHover = (record, index) => {
    setHoveredRowRecord(record);
  };

  const handleRowLeave = () => {
    setHoveredRowRecord({});
  };

  const questionItems = ["Implemented", "Automated"];

  const submitQuestionModalForm = async (values) => {
    console.log("values inside submit question modal form", values);

    // console.log("implemeneeeeettttttttttt", selectedRecord);

    setSubmitQuestionLoading(true);
    setQuestionArray(values);

    console.log("Selected record for question model", selectedRecord);
    console.log("create question", subCategoryParentRecord.sub_category_id);
    let params = {};
    let category_id = "";
    let sub_category_id = "";
    let sub_category_item_id = "";

    let question = values["questions"];
    console.log("question==============", question);
    if (
      selectedRecord.hasOwnProperty("sub_category_id") &&
      !selectedRecord.hasOwnProperty("sub_category_item_")
    ) {
      sub_category_id = subCategoryParentRecord.sub_category_id;
      category_id = subCategoryParentRecord.category_id;

      params = {
        category_id,
        sub_category_id,
        question,
      };
    } else if (selectedRecord.hasOwnProperty("sub_category_item_")) {
      console.log("Itemmmmmmmmmm", selectedRecord);
      sub_category_item_id = selectedRecord.sub_category_item_;
      sub_category_id = selectedRecord.sub_category_id;
      category_id = subCategoryParentRecord.category_id;
      params = {
        category_id,
        sub_category_id,
        sub_category_item_id,
        question,
      };
    } else {
      console.log("category child record======", categoryChildRecord);
      category_id = categoryChildRecord.category_id;
      params = {
        category_id,
        question,
      };
    }
    if (answerType === "boolean") {
      console.log("params inside  boolean type", params);
      params.question.map((que, index) => {
        params.question[index]["answer_type"] = "boolean";
      });
      console.log("params inside boolean after adding answer type", params);
    }
    if (answerType === "custom") {
      console.log("params inside custom if", params);
      const qna = values.custom_questions;
      for (let i = 0; i < qna.length; i++) {
        console.log("inside loop", i, qna[i]["answers"]);

        let answers = qna[i]["answers"];
        values.custom_questions[i]["answer_type"] = answerTypeForCustomQuestion;
        let sum = 0;
        for (let j = 0; j < answers.length; j++) {
          console.log(
            `weightage for ${answers[j]["answer"]} is ${answers[j]["weightage"]}`
          );
          sum = sum + answers[j]["weightage"];
        }
        if (sum !== 100) {
          message.error(
            `Please make sure the sum of weightages of all the answers of this question is 100 `
          );
          setSubmitQuestionLoading(false);
          // handleCancel();
          return;
        }
      }
      console.log(
        `category id is ${category_id}, sub category id is ${sub_category_id}, sub category item id is ${sub_category_item_id}`
      );

      console.log("questions outside loop", values.custom_questions);
      params["question"] = values.custom_questions;
      console.log("final params for custom questions", params);
    }

    console.log("question object inside if", params);
    const response = await axios.post(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/question/bulk-create`,
      params
    );
    setIsQuestionModalOpen(false);
    console.log("Response Create Question", response);
    if (response.data.statusCode === 201) {
      setSubmitQuestionLoading(false);
      message.success("Question created successfully!!");
      setLoading(false);
    } else {
      setSubmitQuestionLoading(false);
      message.error("Cannot create question");
    }
    // form.resetFields();
    handleCancel();
    fetchData();
  };

  const submitForm = async () => {
    setLoading(true);
    console.log("selected record inside submit form==", selectedRecord);
    if (
      selectedRecord.hasOwnProperty("module_id") &&
      !selectedRecord.hasOwnProperty("process_id")
    ) {
      console.log("create process", selectedRecord.module_id);
      let module_id = selectedRecord.module_id;
      let params = {
        module_id,
        process: [
          {
            name: name,
            description: description,
          },
        ],
      };
      console.log("process object inside if", params);
      let response = await axios.post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/process`,
        params
      );
      console.log("Response Create Org", response);
      if (response.data.statusCode === 201) {
        message.success("Process created successfully!!");
        setLoading(false);
      } else {
        message.error("Cannot create process");
      }
      setSelectedRecord({});
      handleCancel();
      fetchData();
    }
    if (
      selectedRecord.hasOwnProperty("process_id") &&
      !selectedRecord.hasOwnProperty("stage_id")
    ) {
      console.log("create stage", selectedRecord.process_id);
      let process_id = selectedRecord.process_id;
      let params = {
        process_id,
        stage: [
          {
            name: name,
            description: description,
          },
        ],
      };
      console.log("stage object inside if", params);
      let response = await axios.post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/stage`,
        params
      );
      console.log("Response Create Stage", response);
      if (response.data.statusCode === 201) {
        message.success("Stage created successfully!!");
        setLoading(false);
      } else {
        message.error("Cannot create stage");
      }
      handleCancel();
      fetchData();
    }

    if (
      selectedRecord.hasOwnProperty("stage_id") &&
      !selectedRecord.hasOwnProperty("category_id")
    ) {
      console.log("create category", selectedRecord.stage_id);
      let stage_id = selectedRecord.stage_id;
      let params = {
        stage_id,
        category: [
          {
            name: name,
            description: description,
          },
        ],
      };
      console.log("category object inside if", params);
      let response = await axios.post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/category`,
        params
      );
      console.log("Response Create Category", response);
      if (response.data.statusCode === 201) {
        message.success(" Category created successfully!!");
        setLoading(false);
      } else {
        message.error("Cannot create stage");
      }
      handleCancel();
      fetchData();
    }

    if (
      selectedRecord.hasOwnProperty("category_id") &&
      !selectedRecord.hasOwnProperty("sub_category_id") &&
      multipleChildRecord.hasOwnProperty("subCategoryChild")
    ) {
      console.log("create sub category", selectedRecord.category_id);
      console.log("creating sub category", selectedRecord);
      let category_id = selectedRecord.category_id;
      let params = {
        category_id,
        subCategory: [
          {
            name: name,
            description: description,
          },
        ],
      };
      console.log("sub category object inside if", params);
      let response = await axios.post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/subcategory`,
        params
      );
      console.log("Response Create Sub Category", response);
      if (response.data.statusCode === 201) {
        message.success("Sub Category created successfully!!");
        setLoading(false);
      } else {
        message.error("Cannot create sub category");
      }
      handleCancel();
      fetchData();
    }

    if (
      (selectedRecord.hasOwnProperty("sub_category_id") &&
        !selectedRecord.hasOwnProperty("question_id") &&
        subCategoryChildRecord.hasOwnProperty("subCategoryQuestionChild")) ||
      (selectedRecord.hasOwnProperty("category_id") &&
        !selectedRecord.hasOwnProperty("sub_category_id") &&
        !selectedRecord.hasOwnProperty("question_id") &&
        multipleChildRecord.hasOwnProperty("questionChild"))
    ) {
      console.log("create question", selectedRecord.category_id);
      let category_id = selectedRecord.category_id;
      let sub_category_id = "";
      let sub_category_item_id = "";
      if (selectedRecord.hasOwnProperty("sub_category_id"))
        sub_category_id = selectedRecord.sub_category_id;
      if (selectedRecord.hasOwnProperty("sub_category_item_"))
        sub_category_item_id = selectedRecord.sub_category_item_;
      else sub_category_id = null;
      let params = {
        category_id,
        sub_category_id,
        sub_category_item_id,
        name,
        description,
      };
      console.log("question object inside if", params);
      let response = await axios.post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/question`,
        params
      );
      console.log("Response Create Question", response);
      if (response.data.statusCode === 201) {
        message.success("Question created successfully!!");
        setLoading(false);
      } else {
        message.error("Cannot create question");
      }
      handleCancel();
      fetchData();
    }
    if (subCategoryChildRecord.hasOwnProperty("subCategoryItemChild")) {
      console.log("create question", selectedRecord.category_id);
      let sub_category_id = selectedRecord.sub_category_id;

      let params = {
        sub_category_id,
        name,
        description,
      };
      console.log("sub category item object inside if", params);
      let response = await axios.post(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/subcategory-item`,
        params
      );
      console.log("Response Create Sub Category Item", response);
      if (response.data.statusCode === 201) {
        message.success("Sub Category Item created successfully!!");
        setLoading(false);
      } else {
        message.error("Cannot create sub category item");
      }
      handleCancel();
      fetchData();
    }
  };
  const setChildRecord = (record) => {
    console.log("before setting category", record);
    setCategoryChildRecord(record);
  };
  const updateAnswerType = (e) => {
    console.log("inside update answer type", e, answerType);
    setAnswerType(e.target.value);
  };

  const updateAnswerTypeForCustomQuestion = (e) => {
    console.log(
      "inside update answer type for custom question",
      e,
      answerTypeForCustomQuestion
    );
    setAnswerTypeForCustomQuestion(e.target.value);
  };

  const setChildRecordForSC = (record) => {
    console.log("before setting sub category", record);
    setSubCategoryParentRecord(record);
  };

  const columns = [
    {
      dataIndex: "",
      key: "",
      width: 70,
      render: (text, record, index) => {
        const items = [
          {
            key: "process",
            label: <a onClick={() => showModal(record)}>Add Process</a>,
          },
          {
            key: "file",
            label: (
              <a onClick={() => showFileUploadModal(record)}>Upload CSV File</a>
            ),
          },
        ];
        return (
          <div
            style={{
              marginLeft: "10px",
              display: hoveredRowRecord === record ? "block" : "none",
            }}
          >
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottomLeft"
              arrow
            >
              <a style={{ color: "black" }}>
                <PlusOutlined />
              </a>
            </Dropdown>
          </div>
        );
      },
    },
    {
      title: "Order",
      key: "",
      dataIndex: "",
      width: 70,
      render: (record, value, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Item Type",
      dataIndex: "",
      key: "x",
      width: 90,
      render: () => <div>Module</div>,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Title",
      // dataIndex: "name",
      // key: "name",
      width: 200,
      render: (record) => {
        return (
          <div>
            <TrophyOutlined style={{ fontSize: "15px", marginRight: "5px" }} />
            {record.name}
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">MasterData</div>
              <Breadcrumb location={props.location} />
            </div>
          </Col>
        </Row>

        <div className="my-4 cloud-charts">
          <div className="card mt-5">
            <div className="card-body p-5">
              <Table
                loading={loading}
                scroll={{ y: 400 }}
                pagination={{ pageSize: 10 }}
                onRow={(record, index) => ({
                  onMouseEnter: () => handleRowHover(record, index),
                  onMouseLeave: handleRowLeave,
                })}
                rowKey="module_id"
                columns={columns}
                expandable={{
                  expandedRowRender: (record) => {
                    const processColumns = [
                      {
                        dataIndex: "",
                        key: "",
                        width: 70,
                        render: (text, record, index) => (
                          <div
                            style={{
                              marginLeft: "10px",
                              display:
                                hoveredRowRecord === record ? "block" : "none",
                            }}
                          >
                            <a style={{ color: "black" }}>
                              <Tooltip title="Add Stage" placement="right">
                                <PlusOutlined
                                  onClick={() => showModal(record)}
                                />
                              </Tooltip>
                            </a>
                          </div>
                        ),
                      },

                      {
                        dataIndex: "",
                        key: "x",
                        render: () => (
                          <div style={{ marginLeft: "70px" }}> Process</div>
                        ),
                        width: 180,
                      },
                      Table.EXPAND_COLUMN,
                      {
                        // dataIndex: "name",
                        // key: "process_id",
                        dataIndex: "",
                        key: "",
                        render: (record) => {
                          return (
                            <div>
                              <ReadOutlined
                                style={{
                                  fontSize: "15px",
                                  marginRight: "5px",
                                }}
                              />

                              {record.name}
                            </div>
                          );
                        },
                      },
                    ];
                    return (
                      <Table
                        onRow={(record, index) => ({
                          onMouseEnter: () => handleRowHover(record, index),
                          onMouseLeave: handleRowLeave,
                        })}
                        className="masterTable"
                        style={{ marginLeft: "-50px" }}
                        rowKey="process_id"
                        columns={processColumns}
                        dataSource={record.processes}
                        expandable={{
                          rowExpandable: (process) =>
                            process.stages.length !== 0,
                          expandedRowRender: (process) => {
                            const stageColumns = [
                              {
                                dataIndex: "",
                                key: "",
                                width: 70,
                                render: (text, record, index) => (
                                  <div
                                    style={{
                                      marginLeft: "10px",
                                      display:
                                        hoveredRowRecord === record
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <a style={{ color: "black" }}>
                                      <Tooltip
                                        title="Add Category"
                                        placement="right"
                                      >
                                        <PlusOutlined
                                          onClick={() => showModal(record)}
                                        />
                                      </Tooltip>
                                    </a>
                                  </div>
                                ),
                              },

                              {
                                dataIndex: "",
                                key: "x",
                                render: () => (
                                  <div style={{ marginLeft: "70px" }}>
                                    {" "}
                                    Stage
                                  </div>
                                ),
                                width: 220,
                              },
                              Table.EXPAND_COLUMN,
                              {
                                //  title: "Stage Name",
                                // dataIndex: "name",
                                //key: "stage_id",
                                //width: 30,

                                dataIndex: "",
                                key: "",
                                render: (record) => {
                                  return (
                                    <div>
                                      <ProjectOutlined
                                        style={{
                                          fontSize: "15px",
                                          marginRight: "5px",
                                        }}
                                      />

                                      {record.name}
                                    </div>
                                  );
                                },
                              },
                            ];
                            return (
                              <Table
                                onRow={(record, index) => ({
                                  onMouseEnter: () =>
                                    handleRowHover(record, index),
                                  onMouseLeave: handleRowLeave,
                                })}
                                style={{ marginLeft: "-50px" }}
                                className="masterTable"
                                rowKey="stage_id"
                                columns={stageColumns}
                                dataSource={process.stages}
                                expandable={{
                                  rowExpandable: (stage) =>
                                    stage.categories.length !== 0,
                                  expandedRowRender: (stage) => {
                                    const categoryColumns = [
                                      {
                                        dataIndex: "",
                                        key: "",
                                        width: 70,
                                        render: (text, record, index) => {
                                          if (record.questions.length < 1000) {
                                            return (
                                              <div
                                                style={{
                                                  marginLeft: "10px",
                                                  display:
                                                    hoveredRowRecord === record
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <Dropdown
                                                  menu={{
                                                    items,
                                                  }}
                                                  trigger={["click"]}
                                                  placement="bottomLeft"
                                                  arrow
                                                >
                                                  <a style={{ color: "black" }}>
                                                    <PlusOutlined
                                                      onClick={() =>
                                                        setChildRecord(record)
                                                      }
                                                    />
                                                  </a>
                                                </Dropdown>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div
                                                style={{
                                                  marginLeft: "10px",
                                                  display:
                                                    hoveredRowRecord === record
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <a style={{ color: "black" }}>
                                                  <Tooltip
                                                    title="Add Sub Category"
                                                    placement="right"
                                                  >
                                                    <PlusOutlined
                                                      onClick={() =>
                                                        showModal(record)
                                                      }
                                                    />
                                                  </Tooltip>
                                                </a>
                                              </div>
                                            );
                                          }
                                        },
                                      },
                                      {
                                        dataIndex: "",
                                        key: "x",
                                        render: () => (
                                          <div
                                            style={{
                                              marginLeft: "70px",
                                            }}
                                          >
                                            {" "}
                                            Category
                                          </div>
                                        ),
                                        width: 260,
                                      },
                                      Table.EXPAND_COLUMN,
                                      {
                                        //  title: "Category Name",
                                        // dataIndex: "name",
                                        // key: "category_id",
                                        // width: 300,
                                        dataIndex: "",
                                        key: "",
                                        render: (record) => {
                                          return (
                                            <div>
                                              <ApartmentOutlined
                                                style={{
                                                  fontSize: "15px",
                                                  marginRight: "5px",
                                                }}
                                              />

                                              {record.name}
                                            </div>
                                          );
                                        },
                                      },
                                    ];
                                    const s_record = {
                                      subCategoryChild: "true",
                                    };
                                    const q_record = { questionChild: "true" };
                                    const items = [
                                      {
                                        key: "subCategory",
                                        label: (
                                          <a
                                            onClick={() => showModal(s_record)}
                                          >
                                            Sub Category
                                          </a>
                                        ),
                                      },
                                      {
                                        key: "question",
                                        label: (
                                          <a
                                            onClick={() =>
                                              showQuestionModal(q_record)
                                            }
                                          >
                                            Question
                                          </a>
                                        ),
                                      },
                                    ];
                                    return (
                                      <Table
                                        onRow={(record, index) => ({
                                          onMouseEnter: () =>
                                            handleRowHover(record, index),
                                          onMouseLeave: handleRowLeave,
                                        })}
                                        className="masterTable"
                                        style={{ marginLeft: "-50px" }}
                                        rowKey="category_id"
                                        columns={categoryColumns}
                                        dataSource={stage.categories}
                                        expandable={{
                                          rowExpandable: (category) =>
                                            category.sub_categories.length !==
                                              0 ||
                                            category.questions.length !== 0,
                                          expandedRowRender: (category) => {
                                            const subCategoryDataSource =
                                              category.sub_categories;
                                            const questionDataSource =
                                              category.questions;

                                            const mergedColumns = [
                                              {
                                                dataIndex: "",
                                                key: "",
                                                width: 70,
                                                render: (
                                                  text,
                                                  sc_record,
                                                  index
                                                ) => {
                                                  {
                                                    if (
                                                      !sc_record.hasOwnProperty(
                                                        "question_id"
                                                      )
                                                    ) {
                                                      if (
                                                        sc_record.questions
                                                          .length < 1000
                                                      ) {
                                                        return (
                                                          <div
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                              display:
                                                                hoveredRowRecord ===
                                                                sc_record
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <Dropdown
                                                              menu={{
                                                                items,
                                                              }}
                                                              trigger={[
                                                                "click",
                                                              ]}
                                                              placement="bottomLeft"
                                                              arrow
                                                            >
                                                              <a
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                }}
                                                              >
                                                                <PlusOutlined
                                                                  onClick={() =>
                                                                    setChildRecordForSC(
                                                                      sc_record
                                                                    )
                                                                  }
                                                                />
                                                              </a>
                                                            </Dropdown>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                              display:
                                                                hoveredRowRecord ===
                                                                sc_record
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <a
                                                              style={{
                                                                color: "black",
                                                              }}
                                                            >
                                                              <Tooltip
                                                                title="Add Sub Category Item"
                                                                placement="right"
                                                              >
                                                                <PlusOutlined
                                                                  onClick={() =>
                                                                    showModal(
                                                                      sc_record
                                                                    )
                                                                  }
                                                                />
                                                              </Tooltip>
                                                            </a>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  }
                                                },
                                              },
                                              {
                                                dataIndex: "x",
                                                key: "x",
                                                render: (_, record) => {
                                                  if (
                                                    record.hasOwnProperty(
                                                      "question_id"
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        style={{
                                                          marginLeft: "70px",
                                                        }}
                                                      >
                                                        Question
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div
                                                        style={{
                                                          marginLeft: "70px",
                                                        }}
                                                      >
                                                        Sub Category
                                                      </div>
                                                    );
                                                  }
                                                },
                                                width: 300,
                                              },
                                              Table.EXPAND_COLUMN,
                                              {
                                                dataIndex: "",
                                                key: "",
                                                render: (_, record) => {
                                                  if (
                                                    record.hasOwnProperty(
                                                      "question_id"
                                                    )
                                                  ) {
                                                    return (
                                                      <div>
                                                        <QuestionCircleOutlined
                                                          style={{
                                                            fontSize: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                        />

                                                        {record.name}
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div>
                                                        <SnippetsOutlined
                                                          style={{
                                                            fontSize: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                        />

                                                        {record.name}
                                                      </div>
                                                    );
                                                  }
                                                },
                                              },
                                            ];
                                            const s_item_record = {
                                              subCategoryItemChild: "true",
                                            };
                                            const s_question_record = {
                                              subCategoryQuestionChild: "true",
                                            };
                                            const items = [
                                              {
                                                key: "subCategoryItem",
                                                label: (
                                                  <a
                                                    onClick={() =>
                                                      showModal(s_item_record)
                                                    }
                                                  >
                                                    Sub Category Item
                                                  </a>
                                                ),
                                              },
                                              {
                                                key: "subCategoryQuestion",
                                                label: (
                                                  <a
                                                    onClick={() =>
                                                      showQuestionModal(
                                                        s_question_record
                                                      )
                                                    }
                                                  >
                                                    Question
                                                  </a>
                                                ),
                                              },
                                            ];

                                            return (
                                              <Table
                                                onRow={(record, index) => ({
                                                  onMouseEnter: () =>
                                                    handleRowHover(
                                                      record,
                                                      index
                                                    ),
                                                  onMouseLeave: handleRowLeave,
                                                })}
                                                style={{
                                                  marginLeft: "-50px",
                                                }}
                                                rowKey="sub_category_id"
                                                columns={mergedColumns}
                                                dataSource={[
                                                  ...questionDataSource,
                                                  ...subCategoryDataSource,
                                                ]}
                                                expandable={{
                                                  expandedRowRender: (
                                                    subCategory
                                                  ) => {
                                                    if (
                                                      subCategory.hasOwnProperty(
                                                        "question_id"
                                                      )
                                                    ) {
                                                      console.log(
                                                        "returning nullllllllllllllll"
                                                      );
                                                      return null;
                                                    }

                                                    const subCategoryItemsDataSource =
                                                      subCategory.sub_category_items;
                                                    const filteredData =
                                                      subCategoryItemsDataSource.filter(
                                                        (item) =>
                                                          item.name !== null
                                                      );

                                                    const subCategoryQuestionsDataSource =
                                                      subCategory.questions;
                                                    const mergedColumns = [
                                                      {
                                                        dataIndex: "",
                                                        key: "",
                                                        width: 40,
                                                        render: (
                                                          text,
                                                          record,
                                                          index
                                                        ) => {
                                                          if (
                                                            record.hasOwnProperty(
                                                              "sub_category_item_"
                                                            )
                                                          ) {
                                                            if (
                                                              record.questions
                                                                .length < 2
                                                            ) {
                                                              return (
                                                                <div
                                                                  style={{
                                                                    marginLeft:
                                                                      "10px",
                                                                    display:
                                                                      hoveredRowRecord ===
                                                                      record
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  <a
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title="Add Question"
                                                                      placement="right"
                                                                    >
                                                                      {" "}
                                                                      <PlusOutlined
                                                                        onClick={() =>
                                                                          showQuestionModal(
                                                                            record,
                                                                            subCategory
                                                                          )
                                                                        }
                                                                      />
                                                                    </Tooltip>
                                                                  </a>
                                                                </div>
                                                              );
                                                            }
                                                          }
                                                        },
                                                      },
                                                      {
                                                        dataIndex: "",
                                                        key: "x",
                                                        render: (
                                                          text,
                                                          record,
                                                          index
                                                        ) => {
                                                          if (
                                                            record.hasOwnProperty(
                                                              "question_id"
                                                            )
                                                          ) {
                                                            return (
                                                              <div
                                                                style={{
                                                                  marginLeft:
                                                                    "103px",
                                                                }}
                                                              >
                                                                Question
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <div
                                                                style={{
                                                                  marginLeft:
                                                                    "103px",
                                                                }}
                                                              >
                                                                Sub Category
                                                                Item
                                                              </div>
                                                            );
                                                          }
                                                        },
                                                        width: 350,
                                                      },
                                                      Table.EXPAND_COLUMN,
                                                      {
                                                        //  title: "Question",
                                                        // dataIndex: "name",
                                                        // key: "question_i",
                                                        // width: 300,
                                                        dataIndex: "",
                                                        key: "",
                                                        render: (record) => {
                                                          if (
                                                            record.hasOwnProperty(
                                                              "question_id"
                                                            )
                                                          ) {
                                                            return (
                                                              <div>
                                                                <QuestionCircleOutlined
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                />

                                                                {record.name}
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <div>
                                                                <BulbOutlined
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                />

                                                                {record.name}
                                                              </div>
                                                            );
                                                          }
                                                        },
                                                      },
                                                    ];
                                                    return (
                                                      <Table
                                                        rowKey="sub_category_item_id"
                                                        onRow={(
                                                          record,
                                                          index
                                                        ) => ({
                                                          onMouseEnter: () =>
                                                            handleRowHover(
                                                              record,
                                                              index
                                                            ),
                                                          onMouseLeave:
                                                            handleRowLeave,
                                                        })}
                                                        expandable={{
                                                          expandedRowRender: (
                                                            sub_category_item
                                                          ) => {
                                                            if (
                                                              sub_category_item.hasOwnProperty(
                                                                "question_id"
                                                              )
                                                            ) {
                                                              return null;
                                                            }
                                                            const filteredQuestions =
                                                              sub_category_item.questions.filter(
                                                                (item) =>
                                                                  item.name !==
                                                                  null
                                                              );
                                                            const questionColumns =
                                                              [
                                                                {
                                                                  dataIndex: "",
                                                                  key: "x",
                                                                  render: (
                                                                    text,
                                                                    record,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        style={{
                                                                          marginLeft:
                                                                            "145px",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        Question
                                                                      </div>
                                                                    );
                                                                  },
                                                                  width: 450,
                                                                },

                                                                {
                                                                  //  title: "Question",
                                                                  // dataIndex: "name",
                                                                  // key: "question_i",
                                                                  // width: 300,
                                                                  dataIndex: "",
                                                                  key: "",
                                                                  render: (
                                                                    record
                                                                  ) => {
                                                                    return (
                                                                      <div>
                                                                        <QuestionCircleOutlined
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            marginRight:
                                                                              "5px",
                                                                          }}
                                                                        />

                                                                        {
                                                                          record.name
                                                                        }
                                                                      </div>
                                                                    );
                                                                  },
                                                                },
                                                              ];
                                                            return (
                                                              <Table
                                                                onRow={(
                                                                  record,
                                                                  index
                                                                ) => ({
                                                                  onMouseEnter:
                                                                    () =>
                                                                      handleRowHover(
                                                                        record,
                                                                        index
                                                                      ),
                                                                  onMouseLeave:
                                                                    handleRowLeave,
                                                                })}
                                                                style={{
                                                                  marginLeft:
                                                                    "-50px",
                                                                }}
                                                                columns={
                                                                  questionColumns
                                                                }
                                                                dataSource={
                                                                  filteredQuestions
                                                                }
                                                                pagination={
                                                                  false
                                                                }
                                                              />
                                                            );
                                                          },

                                                          expandIcon: ({
                                                            expanded,
                                                            onExpand,
                                                            record,
                                                          }) =>
                                                            expanded ? (
                                                              <DownOutlined
                                                                style={{
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                                onClick={(e) =>
                                                                  onExpand(
                                                                    record,
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            ) : (
                                                              <RightOutlined
                                                                style={{
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                                onClick={(e) =>
                                                                  onExpand(
                                                                    record,
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            ),
                                                        }}
                                                        style={{
                                                          marginLeft: "-50px",
                                                        }}
                                                        className="masterTable"
                                                        columns={mergedColumns}
                                                        dataSource={[
                                                          // ...subCategoryItemsDataSource,
                                                          ...filteredData,
                                                          ...subCategoryQuestionsDataSource,
                                                        ]}
                                                        pagination={false}
                                                      />
                                                    );
                                                  },
                                                  expandIcon: ({
                                                    expanded,
                                                    onExpand,
                                                    record,
                                                  }) =>
                                                    expanded ? (
                                                      <DownOutlined
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                        onClick={(e) =>
                                                          onExpand(record, e)
                                                        }
                                                      />
                                                    ) : (
                                                      <RightOutlined
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                        onClick={(e) =>
                                                          onExpand(record, e)
                                                        }
                                                      />
                                                    ),
                                                }}
                                                pagination={false}
                                              />
                                            );
                                          },
                                          // rowExpandable: (record) =>
                                          //   !record.hasOwnProperty(
                                          //     "question_id"
                                          //   ),
                                          expandIcon: ({
                                            expanded,
                                            onExpand,
                                            record,
                                          }) =>
                                            expanded ? (
                                              <DownOutlined
                                                style={{
                                                  fontSize: "12px",
                                                }}
                                                onClick={(e) =>
                                                  onExpand(record, e)
                                                }
                                              />
                                            ) : (
                                              <RightOutlined
                                                style={{
                                                  fontSize: "12px",
                                                }}
                                                onClick={(e) =>
                                                  onExpand(record, e)
                                                }
                                              />
                                            ),
                                        }}
                                        pagination={false}
                                      />
                                    );
                                  },
                                  expandIcon: ({
                                    expanded,
                                    onExpand,
                                    record,
                                  }) =>
                                    expanded ? (
                                      <DownOutlined
                                        style={{ fontSize: "12px" }}
                                        onClick={(e) => onExpand(record, e)}
                                      />
                                    ) : (
                                      <RightOutlined
                                        style={{ fontSize: "12px" }}
                                        onClick={(e) => onExpand(record, e)}
                                      />
                                    ),
                                }}
                                pagination={false}
                              />
                            );
                          },
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <DownOutlined
                                style={{ fontSize: "12px" }}
                                onClick={(e) => onExpand(record, e)}
                              />
                            ) : (
                              <RightOutlined
                                style={{ fontSize: "12px" }}
                                onClick={(e) => onExpand(record, e)}
                              />
                            ),
                        }}
                        pagination={false}
                      />
                    );
                  },
                  rowExpandable: (record) => record.processes.length !== 0,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <DownOutlined
                        style={{ fontSize: "12px" }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <RightOutlined
                        style={{ fontSize: "12px" }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                }}
                dataSource={data}
                size="small"
              />
              <Modal
                title={"Create " + itemType}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
              >
                <Form name="itemCreation">
                  <Form.Item name="name">
                    <Input
                      placeholder={"Enter name"}
                      name="name"
                      value={name}
                      onChange={onChangeName}
                    />
                  </Form.Item>
                  <Form.Item name="description">
                    <Input
                      placeholder="Enter description"
                      name="description"
                      value={description}
                      onChange={onChangeDesc}
                    />
                  </Form.Item>
                  <Form.Item className="float-end f-right orgbutton">
                    <Button
                      type="primary"
                      className="ok-modal"
                      htmlType="submit"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      onClick={handleCancel}
                      type="default"
                      className="close-modal"
                      htmlType="submit"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
              {/* <Modal
                title={"Create " + itemType}
                open={isQuestionModalOpen}
                destroyOnClose={true}
                onOk={handleQuestionModalOk}
                onCancel={handleQuestionModalCancel}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                width={400}
              >
                <Form
                  name="question_creation_form"
                  onFinish={submitQuestionModalForm}
                  style={{
                    maxWidth: 600,
                    marginTop: "0px",
                  }}
                  autoComplete="off"
                  initialValues={{
                    questions: [{ name: "Select Question" }],
                  }}
                >
                  <Form.List name="questions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            align="baseline"
                            direction="vertical"
                            style={{ display: "flex" }}
                          >
                            <Space>
                              {" "}
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select question",
                                  },
                                ]}
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  //   prevValues.area !== curValues.area ||
                                  prevValues.questionItems !==
                                  curValues.questionItems
                                }
                                {...field}
                                label="Question"
                                name={[field.name, "name"]}
                              >
                                <Select
                                  style={{
                                    width: "330px",
                                    marginTop: "10px",
                                  }}
                                  placeholder="Select Question"
                                >
                                  {questionItems.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <MinusCircleOutlined
                                style={{
                                  display: fields.length > 1 ? "block" : "none",
                                }}
                                onClick={() => remove(field.name)}
                              />
                            </Space>

                            <Form.Item
                              label="Choose Answer Type"
                              style={{ marginLeft: "10px" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please choose answer type",
                                },
                              ]}
                              name={[field.name, "answer_type"]}
                            >
                              <Radio.Group>
                                <Radio value="boolean">Boolean</Radio>
                                <Radio value="score">Score</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Space>
                        ))}

                        <Form.Item style={{ marginTop: "10px" }}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={fields.length >= 2}
                          >
                            Add Question
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item className="float-end f-right orgbutton">
                    <Button
                      type="primary"
                      className="ok-modal"
                      htmlType="submit"
                      loading={submitQuestionLoading}
                    >
                      Submit
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      onClick={handleQuestionModalCancel}
                      type="default"
                      className="close-modal"
                      htmlType="submit"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Modal> */}
              <Modal
                open={isQuestionModalOpen}
                title={"Create " + itemType}
                okButtonProps={{ hidden: true }}
                destroyOnClose={true}
                onOk={handleQuestionModalOk}
                onCancel={handleQuestionModalCancel}
                cancelButtonProps={{ hidden: true }}
              >
                <Form
                  name="question_creation_form"
                  onFinish={submitQuestionModalForm}
                  // onFinish= {submitCustomQuestionModalForm}
                  style={{
                    maxWidth: 600,
                    marginTop: "0px",
                  }}
                  autoComplete="off"
                  form={form}
                >
                  <Form.Item label="Choose Answer Type">
                    <Group onChange={updateAnswerType} value={answerType}>
                      <Radio value="boolean">Boolean</Radio>
                      <Radio value="custom">Custom</Radio>
                    </Group>
                  </Form.Item>
                  {answerType === "none" && (
                    <div>
                      Please choose answer type before creating questions
                    </div>
                  )}
                  {answerType === "boolean" && (
                    <Form.List name="questions">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              align="baseline"
                              // direction="vertical"
                              style={{ display: "flex" }}
                            >
                              {" "}
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select question",
                                  },
                                ]}
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  //   prevValues.area !== curValues.area ||
                                  prevValues.questionItems !==
                                  curValues.questionItems
                                }
                                {...field}
                                label="Question"
                                name={[field.name, "name"]}
                              >
                                <Select
                                  style={{
                                    width: "330px",
                                    marginTop: "10px",
                                  }}
                                  placeholder="Select Question"
                                >
                                  {questionItems.map((item) => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <MinusCircleOutlined
                                style={{
                                  display: fields.length > 1 ? "block" : "none",
                                }}
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}

                          <Form.Item
                            style={{ marginTop: "10px", marginLeft: "7px" }}
                          >
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              disabled={fields.length >= 2}
                            >
                              Add Question
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  )}
                  {answerType === "custom" && (
                    <Form
                      labelCol={{
                        span: 6,
                      }}
                      wrapperCol={{
                        span: 18,
                      }}
                      onFinish={submitQuestionModalForm}
                      form={form}
                      name="custom_question_creation"
                      style={{
                        maxWidth: 600,
                      }}
                      autoComplete="off"
                      initialValues={{
                        custom_questions: [{}],
                      }}
                    >
                      <Form.List name="custom_questions">
                        {(fields, { add, remove }) => (
                          <div
                            style={{
                              display: "flex",
                              rowGap: 16,
                              flexDirection: "column",
                            }}
                          >
                            {fields.map((field) => (
                              <Card
                                size="small"
                                title={`Question ${field.name + 1}`}
                                key={field.key}
                                extra={
                                  <CloseOutlined
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                }
                              >
                                <Form.Item
                                  // label="Name"
                                  name={[field.name, "name"]}
                                >
                                  <TextArea
                                    placeholder="Enter custom question"
                                    rows={2}
                                  />
                                </Form.Item>

                                {/* Nest Form.List */}
                                <Form.Item>
                                  <Group
                                    onChange={updateAnswerTypeForCustomQuestion}
                                    value={answerTypeForCustomQuestion}
                                  >
                                    <Radio value="single_select">
                                      Single Select
                                    </Radio>
                                    <Radio value="multi_select">
                                      Multi Select
                                    </Radio>
                                  </Group>
                                  <Form.Item />
                                  <Form.List name={[field.name, "answers"]}>
                                    {(subFields, subOpt) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: 16,
                                        }}
                                      >
                                        {subFields.map((subField) => (
                                          <Space key={subField.key}>
                                            <Form.Item
                                              noStyle
                                              name={[subField.name, "ans"]}
                                            >
                                              <TextArea
                                                rows={1}
                                                style={{ width: "230px" }}
                                                placeholder="Enter answer"
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              noStyle
                                              name={[
                                                subField.name,
                                                "weightage",
                                              ]}
                                            >
                                              <InputNumber
                                                defaultValue={100}
                                                min={0}
                                                max={100}
                                                formatter={(value) =>
                                                  `${value}%`
                                                }
                                                parser={(value) =>
                                                  value.replace("%", "")
                                                }
                                              />
                                            </Form.Item>
                                            {/* <Form.Item
                                              noStyle
                                              name={[
                                                subField.name,
                                                "weightage",
                                              ]}
                                            >
                                              <Tooltip title="Enter weightage for each answer such that the sum of weightages of all the answers is 100%">
                                                <InputNumber
                                                  defaultValue={100}
                                                  min={0}
                                                  max={100}
                                                  formatter={(value) =>
                                                    `${value}%`
                                                  }
                                                  parser={(value) =>
                                                    value.replace("%", "")
                                                  }
                                                />
                                              </Tooltip>
                                            </Form.Item> */}
                                            <CloseOutlined
                                              onClick={() => {
                                                subOpt.remove(subField.name);
                                              }}
                                            />
                                          </Space>
                                        ))}
                                        <Button
                                          type="dashed"
                                          onClick={() => subOpt.add()}
                                          block
                                          disabled={subFields.length >= 5}
                                        >
                                          + Add Answer
                                        </Button>
                                      </div>
                                    )}
                                  </Form.List>
                                </Form.Item>
                              </Card>
                            ))}

                            <Button
                              type="dashed"
                              style={{
                                visibility: fields.length >= 1 ? "hidden" : "",
                              }}
                              onClick={() => add()}
                              block
                            >
                              + Add Question
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Form>
                  )}
                  <Form.Item className="float-end f-right orgbutton">
                    <Button
                      type="primary"
                      className="ok-modal"
                      htmlType="submit"
                      style={{ marginTop: "10%" }}
                      loading={submitQuestionLoading}
                    >
                      Submit
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      onClick={handleQuestionModalCancel}
                      type="default"
                      className="close-modal"
                      htmlType="submit"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>

              <Modal
                title={"Upload .CSV File"}
                open={isFileUploadModalOpen}
                onOk={handleUploadFileModalOk}
                onCancel={handleUploadFileModalCancel}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
              >
                <Form>
                  <Form.Item>
                    <Typography style={{ fontWeight: "470", fontSize: "16px" }}>
                      *Download file template to fill data before uploading
                    </Typography>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="medium"
                      loading={fileDownloadLoading}
                      onClick={downloadTemplate}
                      style={{ marginTop: "5px" }}
                    >
                      Download
                    </Button>
                  </Form.Item>
                  <Form.Item name="upload">
                    <Dragger {...propsForFileUpload}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag .csv file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Add bulk data inside a module using .csv file
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Form.Item className="float-end f-right orgbutton">
                    <Button
                      type="primary"
                      className="ok-modal"
                      htmlType="submit"
                      onClick={handleUpload}
                      loading={fileUploadLoading}
                    >
                      Upload
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      onClick={handleUploadFileModalCancel}
                      type="default"
                      className="close-modal"
                      htmlType="submit"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
