import React, { useCallback, useEffect, useState } from "react";
import {
  Layout,
  Dropdown,
  Avatar,
  Badge,
  notification,
  Menu,
  Col,
  Row,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import moment from "moment";

import { BellFilled, HomeOutlined } from "@ant-design/icons/lib/icons";
import { CloseOutlined } from "@mui/icons-material";
import axios from "axios";

const { Header } = Layout;

export default function HeaderAdmin(props) {
  const [socket, setSocket] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [notificationData, setNotificationData] = useState([]);
  const handleMessage = useCallback((event) => {
    console.log("Received message from WebSocket API server:", event.data);
    const data = JSON.parse(event.data);
    getAllNotification();
    openNotification(data);
  }, []);

  useEffect(() => {
    getAllNotification();
  }, [socket]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    const socket = new WebSocket(
      `wss://7h4oewb0h8.execute-api.ap-south-1.amazonaws.com/Dev?user_id=${userId}`
    );

    console.log("socketeeeee", socket);
    socket.onopen = () => {
      console.log("WebSocket connection established.");
    };
    socket.onmessage = handleMessage;
    setSocket(socket);
    // socket.onclose = () => {
    //   console.log("Disconnected from WebSocket API server");
    // };
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // return () => {
    //   socket.close();
    //   console.log("WebSocket connection closed");
    // };
  }, [handleMessage]);
  const handleDateTime = (datetime) => {
    const dateString = datetime;
    const date = new Date(dateString);

    var options = {
      timeZone: "Asia/Kolkata",
    };

    var formattedDate = date
      .toLocaleDateString("en-IN", options)
      .split("/")
      .join("-");
    var formattedTime = date.toLocaleTimeString("en-IN", options);
    const updatedDate = formattedDate.split("-");

    const monthName = moment(updatedDate[1], "M").format("MMMM");

    return `${updatedDate[0]} ${monthName} ${updatedDate[2]}  ${formattedTime}`;
  };
  const handleDeleteNotification = async (user_id, notification_id) => {
    const data = notificationData.filter((item) => item.id !== notification_id);
    setNotificationData(data);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/notification/delete-notification";
    const params = {
      user_id: user_id,
      notification_id: notification_id,
    };
    const deleteData = await axios.delete(url, { params });
  };
  const getAllNotification = async () => {
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/notification/get-all-notifications";
    const params = {
      user_id: localStorage.getItem("userId"),
    };
    try {
      const response = await axios.get(url, { params });
      console.log("response from notification", response.data);
      setNotificationData(response.data);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const openNotification = (data) => {
    api.open({
      message: "New Items Added",
      description: data,
      duration: 0,
    });
  };

  const logout = () => {
    console.log("called logout");
    localStorage.clear();
    navigate("/");
  };

  const menu = (
    <Menu
      style={{
        // marginRight: "-150px",

        width: "410px",
        marginTop: "20px",
        height: notificationData.length > 4 ? "500px" : "none",
        overflowY: "scroll",
      }}
    >
      {notificationData.map((item, index) => (
        <Menu.Item
          key={item.id}
          onClick={(e) => e.preventDefault()}
          style={{
            fontSize: "16px",
            color: "rgba(0,0,0,.45)",
            // width: "390px",
          }}
        >
          <span className="dot"></span>. &nbsp;&nbsp;
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {handleDateTime(item.createdAt)} &nbsp;&nbsp;{" "}
          </span>{" "}
          <br />
          <div style={{ display: "flex" }}>
            <div> {item.notification}</div>
            <div>
              <CloseOutlined
                style={{ fontSize: "13px", marginLeft: "5px" }}
                onClick={() => {
                  handleDeleteNotification(item.user_id, item.id);
                }}
              />
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const items = [
    {
      label: (
        <Link
          to={
            localStorage.getItem("access_level") === "ADMIN" ||
            localStorage.getItem("access_level") === "ORGANIZATION"
              ? "/organization"
              : "/projects"
          }
          className="ms-4 fs-4"
        >
          <HomeOutlined />
          <span className="ms-4 menu-item-name" style={{ paddingLeft: "5px" }}>
            Home
          </span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <span className="ms-4 fs-4" onClick={props.signOut}>
          <LogoutOutlined />
          <span
            className="ms-4 menu-item-name"
            onClick={logout}
            style={{ paddingLeft: "5px" }}
          >
            Sign Out
          </span>
        </span>
      ),
      key: "1",
    },
  ];

  return (
    <Header
      className="header-admin site-layout-background"
      style={{ padding: 0 }}
    >
      <Row align="middle">
        <Col>
          {React.createElement(
            props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: props.toggle,
            }
          )}
        </Col>
        <Col flex="auto">
          <SiderDrawerPhone user={props.user} />
          {contextHolder}
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={["click"]}>
            <span style={{ paddingRight: "20px" }}>
              <Badge
                count={notificationData.length}
                size={notificationData.length >= 10 ? "default" : "small"}
                showZero
              >
                <Avatar
                  style={{ backgroundColor: "#3b404f", cursor: "pointer" }}
                  size={26}
                  icon={<BellFilled />}
                />
              </Badge>
            </span>
          </Dropdown>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div
              className="avatar-user-details"
              onClick={(e) => e.preventDefault()}
            >
              <div className="avatar-box">
                <span className="avatar-name">
                  <strong>{`${userData.first_name} ${userData.last_name}`}</strong>
                </span>
                <div className="avatar-img">
                  <Avatar
                    style={{ backgroundColor: "#3b404f" }}
                    size={40}
                    icon={<UserOutlined />}
                  />
                </div>
              </div>
            </div>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}
