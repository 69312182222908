import React, { useEffect, useState } from "react";

import { Card, Spin, Empty } from "antd";
import axios from "axios";
import { Pie } from "@ant-design/plots";

export default function PiChart(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const params = {
    project_id: props.projectId,
    module_id: props.moduleId,
  };
  useEffect(() => {
    console.log("props inside pie chartttt", props);
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    setLoading(true);

    let final_array = [];
    let total = 0;
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/process-overall-score";
    axios
      .get(url, { params })
      .then((res) => {
        console.log("response from pie chart", res.data);
        res.data.forEach((element) => {
          let obj = {};
          let x = Number(element["total_percentage"].toFixed(2));
          total += x;
          obj["process"] = element["name"];
          obj["percentage"] = x;
          final_array.push(obj);
        });
        let totalObj = {};
        totalObj["process"] = "Remaining";
        totalObj["percentage"] = 100 - total;

        final_array.push(totalObj);
        props.setPieDataForDoc(`${100 - total.toFixed(2)}%`);
        console.log("final array of pie chart", final_array);
        setData(final_array);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
        setLoading(false);
      });
  };

  const config = {
    appendPadding: 15,
    width: 200,
    data,
    angleField: "percentage",
    colorField: "process",
    radius: 0.8,
    label: {
      type: "outer",
      style: {
        overflow: "visible",
      },
    },
    legend: {
      position: "bottom",
      flipPage: false,
      style: {
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return (
    <Card title="Overall Score">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div>
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return <Pie {...config} />;
            }
          })()}
        </div>
      )}
    </Card>
  );
}
