import "./App.css";

import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import "antd/dist/antd.min.css";

import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import { routes } from "./util/constants";
import Login from "./Auth/Login";
import { message } from "antd";

function App() {
  const navigate = useNavigate();

  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);
    let exp_time = decodedToken.exp;

    let curr_Date = new Date();
    let curr_time = Number(curr_Date) / 1000;
    if (curr_time >= exp_time) {
      message.error("Token Expired!");
      localStorage.clear();
      navigate("/");
    }
  }

  let location = useLocation();

  return (
    <Routes location={location}>
      <Route exact path={routes.LOGIN} element={<Login />} />

      <Route
        exact
        path={routes.ADMIN_DASHBOARD}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.USERS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.MODULES}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROJECTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROJECTCHARTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROLEVELCHARTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.ORGCHARTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROJECTDATA}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.ORGANIZATION}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.CHARTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROJECTMODULECHARTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.MASTERDATA}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.ADMIN_DASHBOARD}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROJECTSBYID}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROLEVELMODULE}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.PROLEVELPROJECTDATA}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        exact
        path={routes.REPORTS}
        element={
          localStorage.getItem("token") ? (
            <AdminDashboard location={location} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
    </Routes>
  );
}

export default App;
