export const routes = {
  LOGIN: "/",
  USERS: "/users",
  ORGANIZATION: "/organization",
  PROJECTS: "/organization/projects",
  ADMIN_DASHBOARD: "/dashboard",
  PROJECTDATA: "/organization/projects/modules/projectdata",
  PROLEVELPROJECTDATA: "/projects/modules/projectdata",
  MASTERDATA: "/masterdata",
  PROJECTCHARTS: "/organization/projects/modules/projectdata/charts",
  PROJECTMODULECHARTS: "/projects/modules/projectdata/charts",
  MODULES: "/organization/projects/modules",
  PROLEVELMODULE: "/projects/modules",
  CHARTS: "/organization/projects/charts",
  PROLEVELCHARTS: "/projects/charts",
  ORGCHARTS: "/organization/charts",
  PROJECTSBYID: "/projects",
  REPORTS: "/organization/projects/modules/reports",
};
