import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Colors,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Card, Spin, Empty } from "antd";

import axios from "axios";

ChartJS.register(ArcElement, Colors, Tooltip, Legend);

export default function ModuleOverallScore({ projectId }) {
  const [pieData, setPieData] = useState([]);
  const [pieLabel, setPieLable] = useState([]);
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    project_id: projectId,
  };

  useEffect(() => {
    setLoading(true);
    const url =
      " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-overall-score";
    axios
      .get(url, { params })
      .then((res) => {
        const data = res.data;
        let chart_level = [];
        let chart_value = [];
        let yes_count = [];
        data.forEach((element) => {
          chart_level.push(element.name);
          chart_value.push(element.percentage);
          yes_count.push(count);
        });
        setPieLable(chart_level);
        setPieData(chart_value);
        setCount(yes_count);
        setLoading(false);
      })
      .catch((error) => {
        console.log("errro-------", error);
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.dataset.data[context.dataIndex] + "%";
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: "right",
        labels: {
          padding: 30,
        },
        padding: {
          bottom: 20,
        },
      },
      title: {
        display: true,
        text: "Overall Module Score",
        font: {
          size: 20,
        },
      },
    },
  };

  const data = {
    labels: pieLabel,
    datasets: [
      {
        height: "500px",
        data: pieData,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card>
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div style={{ height: "460px" }}>
          {(() => {
            if (pieData.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return (
                <Pie
                  data={data}
                  options={options}
                  width="500px"
                  height="500px"
                />
              );
            }
          })()}
        </div>
      )}
    </Card>
  );
}
