import React, { useState } from "react";
import ModuleOverallScore from "./ModuleOverallScore";
import ItemImplemented from "./ItemImplemented";
import { Col, Row } from "antd";
import BreadCrumbs from "../../BreadCrumbs";
import "../../../App.css";
import MatuarityScore from "./MatuarityScore";
import Percentage from "./Percentage";
import Spiderchart from "../ModuleCharts/SpiderChart";

export default function ModuleChart(props) {
  let proId = props.location.state.project_id;
  const [projectId, setProjectI] = useState(proId);

  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">Module Level Charts</div>
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <br />
        <Row
          gutter={[16, 16]}
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <MatuarityScore projectId={projectId} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Spiderchart projectId={projectId} />
          </Col>
        </Row>{" "}
        <br />
        <Row
          gutter={[16, 16]}
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Col xs={48} sm={48} md={48} lg={24} xl={24} xxl={24}>
            <ItemImplemented projectId={projectId} />
          </Col>
        </Row>
      </div>
    </>
  );
}
