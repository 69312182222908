import { Col, Empty, Row, Select, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import PieChartDashboard from "./PieChartDashboard";
import DashboardCard2 from "./DashboardCard2";

var initialOrgName = [];
var initialOrgId = [];
const { Option } = Select;

export default function DashboardChart() {
  const [initialOrg, setInitialOrg] = useState("");
  const [orgId, setOrgId] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [projectScore, setProjectScore] = useState(0);
  const [devopsScore, setDevopsScore] = useState(0);
  const [securityScore, setSecurityScore] = useState(0);
  const [developmentScore, setDevelopmentScore] = useState(0);
  const [testingScore, setTestingScore] = useState(0);

  useEffect(() => {
    const accessToken = localStorage.getItem(
      "CognitoIdentityServiceProvider.5aikoaqjg4necgijavjq6ttk36.azuread_shruti.goyal@yash.com.accessToken"
    );
    getAllOrganizations();
  }, []);

  //setProjectModuleScore([]);

  // var url =
  //   "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-maturity-score";
  // var params = {};
  // console.log("project state", projects);
  // if (projects.length === 0) {
  //   console.log("initial org", initialOrg, initialOrgId);
  // } else {
  //   for (var i = 0; i < projects.length; i++) {
  //     params = {
  //       project_id: projects[i].project_id,
  //     };

  //     console.log(
  //       "project id for setting module data",
  //       projects[i].project_id
  //     );
  //   }
  // }

  const getMaturityScoreOfProject = (projectId) => {
    var score = 0;
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-maturity-score";
    const params = {
      project_id: projectId,
    };
    axios
      .get(url, { params })
      .then((res) => {
        var result = res.data[0];
        console.log("result after fetching module details", result);
        setDevopsScore(result["DevOps"]);
        setSecurityScore(result["Security"]);
        setDevelopmentScore(result["Development"]);
        setTestingScore(result["Testing"]);
      })
      .catch((error) => {
        console.log("error fetching maturity score", error);
        return error;
      });
    //console.log("outside if score", score);
    //return score;
  };
  const getAllOrganizations = async () => {
    // setProjectModuleScore([]);
    setSelectLoading(true);
    setTableLoading(true);
    let allOrg = [];
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN") {
      setIsAdmin(true);
    }
    const token = localStorage.getItem("token");
    let response = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/organization/get-all-organizations`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("Dashboard Org Response", response.data);
    allOrg = response.data;
    initialOrgName = allOrg[0].name;
    initialOrgId = allOrg[0].organization_id;
    setInitialOrg(initialOrgName);
    setOrgId(initialOrgId);
    setOrganizations(response.data);
    setSelectLoading(false);
    getProjectsByOrgId(initialOrgId);
  };

  const getProjectsByOrgId = async (organization_id) => {
    setTableLoading(true);
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN") {
      setIsAdmin(true);
    }
    if (access_level === "ORGANIZATION" || access_level === "ADMIN") {
      setLoading(true);

      let response = await axios.get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/project/getallprojectbyid?organization_id=${organization_id}`
      );
      console.log("Dashboard get all projects by ID", response.data);
      setProjects(response.data);

      setTableLoading(false);
    } else {
      setTableLoading(true);
      const url =
        " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/access-level-projects";
      const token = localStorage.getItem("token");
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          console.log("dashboard from project level api", res.data);
          setProjects(res.data);
          setTableLoading(false);
        })
        .catch((err) => {
          setTableLoading(false);
          message.info(err.response.data.message);
          console.log("error from token api", err);
        });
    }
  };

  const organizationsOptions = organizations.map((org) => {
    return (
      <Option name={org.name} value={org.organization_id}>
        {org.name}
      </Option>
    );
  });

  const handleOrganizationChange = (value) => {
    let filteredData = organizations.filter((org) => {
      if (value == org.organization_id) {
        setInitialOrg(org.name);
      }
    });
    setOrgId(value);
    // setProjectModuleScore({});
    getProjectsByOrgId(value);
  };

  const columns = [
    {
      title: "S.No.",
      key: "",
      dataIndex: "",
      width: 70,
      render: (record, value, index) => {
        return <div>{index + 1}</div>;
      },
    },
    { title: "Project Name", key: "name", dataIndex: "name" },
    { title: "Description", key: "description", dataIndex: "description" },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (record) => {
        return moment(record.createdAt).format("MMMM Do YYYY");
      },
    },
    {
      title: "DevOps Score",
      key: "DevOps",
      dataIndex: "DevOps",
    },

    {
      title: "Development Score",
      key: "Development",
      dataIndex: "Development",
    },
    {
      title: "Security Score",
      key: "Security",
      dataIndex: "Security",
    },
    {
      title: "Testing Score",
      key: "Testing",
      dataIndex: "Testing",
    },
  ];

  return (
    <>
      <div className="my-4 cloud-charts">
        <div className="card mt-5">
          <div className="card-body p-5">
            <Row gutter={[24, 8]} style={{ margin: " 10px 0px 5px 0" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row gutter={[8, 48]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="d-flex flex-row">
                      <h2 className="m-b-20 text-secondary ">
                        <Row gutter={[8, 8]}>
                          <Col>
                            <label>
                              <h7 style={{ color: "#2c384af2" }}>
                                Organization&nbsp; &nbsp;
                              </h7>
                            </label>
                          </Col>
                          <Col>
                            <Select
                              showSearch
                              value={initialOrg}
                              style={{ width: 200 }}
                              placeholder="Select Organization"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleOrganizationChange}
                              loading={selectLoading}
                            >
                              {organizationsOptions}
                            </Select>
                          </Col>
                        </Row>
                      </h2>
                    </div>
                    <Table
                      className="dashboard"
                      columns={columns}
                      dataSource={projects}
                      bordered={false}
                      loading={tableLoading}
                      size="middle"
                      pagination={false}
                      scroll={{ y: 280 }}
                    ></Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="my-4 cloud-charts ">
        <div className="card mt-5">
          <div className="card-body p-5">{/* <DashboardCard2 /> */}</div>
        </div>
      </div>
    </>
  );
}
