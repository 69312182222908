import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, Empty, Spin } from "antd";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export default function PercentageBar({ organizationId }) {
  const [percentageData, setPercentageData] = useState([]);
  const [percentageLabel, setPercentageLabel] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    organization_id: organizationId,
  };

  useEffect(() => {
    setLoading(true);
    const url =
      " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/org-percent-implement";
    axios
      .get(url, { params })
      .then((res) => {
        const data = res.data[0];
        console.log("response percent====>", data);
        let labels = [];
        let percentageData = [];
        let percentageActualData = [];
        labels = Object.keys(data);
        percentageData = Object.values(data);
        percentageActualData = Object.values(data);
        setPercentageData(percentageActualData);
        setPercentageLabel(labels);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from -------", error);
      });
  }, []);

  const options = {
    scales: {
      x: {
        min: 0,
        max: 100,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          format: {
            style: "percent",
          },
        },
      },
    },

    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.dataset.data[context.dataIndex] + "%";
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: (val, context) => `${val}%`,
      },
      legend: {
        display: false,
      },

      title: {
        display: true,
        text: "Percentage Implementation",
        font: {
          size: 20,
        },
        padding: {
          bottom: 50,
          top: 20,
        },
      },
    },
  };

  const labels = percentageLabel;

  const data = {
    labels,
    datasets: [
      {
        data: percentageData,
      },
    ],
  };

  return (
    <Card>
      <>
        {loading === true ? (
          <div className="TabsLoading">
            <Spin />
          </div>
        ) : (
          <>
            <div>
              {(() => {
                if (percentageData.length === 0) {
                  return (
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 100,
                        margin: "0 0 0 0",
                      }}
                    />
                  );
                } else {
                  return <Bar options={options} data={data} />;
                }
              })()}
            </div>
          </>
        )}
      </>
    </Card>
  );
}
