import React, { useState, useEffect } from "react";
import { Bar } from "@ant-design/plots";
import { Card, Spin, Empty, Typography, Divider } from "antd";
import axios from "axios";

export default function HorizentalBar(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    project_id: props.projectId,
    module_id: props.moduleId,
  };
  useEffect(() => {
    console.log("props inside horizontal bar", props);
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    setLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/maturity-score";
    await axios
      .get(url, { params })
      .then((res) => {
        let data = res.data[0];
        console.log("data from horizontal", data);
        let final_array = [];
        let sum = 0;
        Object.keys(data).forEach((key) => {
          let process_data = {};

          process_data["name"] = key;
          process_data["value"] = data[key];
          sum = sum + data[key];
          final_array.push(process_data);
        });

        console.log("Final array for bar chart", final_array);
        const x = final_array.filter((item) => item["name"] !== "DevOps Score");
        setData(x);
        const avg = Math.round(sum / Object.keys(data).length);
        props.setBarDataForDoc(avg);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
      });
  };

  const config = {
    data,
    appendPadding: 7,
    width: 10,
    xField: "value",
    yField: "name",
    seriesField: "name",
    maxBarWidth: 25,
    legend: {
      position: "top-left",
    },
  };

  return (
    <Card title="Maturity Score">
      {/* <div style={{ background: "#fff" }}>
        <Typography.Title level={5}>Maturity Score</Typography.Title>
        <Divider /> */}
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div style={{ background: "#fff" }}>
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return <Bar {...config} />;
            }
          })()}
        </div>
      )}
      {/* </div> */}
    </Card>
    // <Card title="Maturity Score" style={{ height: "100%", width: "100%" }}>
    //   {loading === true ? (
    //     <div className="TabsLoading">
    //       <Spin />
    //     </div>
    //   ) : (
    //     <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
    //       {data.length === 0 ? (
    //         <Empty
    //           image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    //           imageStyle={{
    //             height: 100,
    //             margin: "0 0 0 0",
    //           }}
    //         />
    //       ) : (
    //         <div style={{ width: "100%" }}>
    //           <Bar {...config} />
    //         </div>
    //       )}
    //     </div>
    //   )}
    // </Card>
  );
}
