import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "./BreadCrumbs";
import DashboardChart from "./DashBoardCharts/DashboardChart";
export default function Dashboard(props) {
  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">Dashboard</div>
              <Breadcrumb location={props.location} />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <DashboardChart />
          </Col>
        </Row>
      </div>
    </>
  );
}
