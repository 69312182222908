import { Button, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import axios from "axios";
import { DownloadOutlined } from "@mui/icons-material";

const ModuleReport = (props) => {
  const [reports, setReports] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const getAllReports = async () => {
    setTableLoading(true);
    console.log("props inside reports", props);

    const moduleId = props.location.state.module_id;
    const projectId = props.location.state.project_id;

    const resp = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/report?projectId=${projectId}&moduleId=${moduleId}`
    );
    console.log("reponse from get all reports", resp);
    setReports(resp.data);
    setTableLoading(false);
  };
  useEffect(() => {
    console.log("props inside module report", props.location.state);
    getAllReports();
  }, []);

  const downloadImage = async (record) => {
    console.log("record inside download image", record);

    const reportName = record.report_name;
    /*  const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/report";
    const params = {
      reportName: reportName,
    }; */
    const moduleName = props.location.state.module_name;

    const resp = await axios.get(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/pre-signed-url/report?reportName=${reportName}&module_name=${moduleName}`
    );

    /* console.log("Response Data =====>",resp);

    const downloadUrl = record.document_url; */

    window.open(`${resp.data}`);
  };

  const title = `${props.location.state.module_name} Reports`;
  const columns = [
    {
      title: "Report Name",
      dataIndex: "report_name",
      key: "report_name",
      //   width: 80,
      width: "40%",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      //   width: 100,
    },
    {
      title: "Action",
      //   width: 60,
      render: (record) => {
        return (
          <Button onClick={() => downloadImage(record)}>
            <DownloadOutlined />
          </Button>
        );
      },
    },
  ];
  return (
    <>
      {/* <div className="my-4 cloud-charts">
        <div className="card mt-5">
          <div className="card-body p-5"></div>
        </div>
      </div> */}
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">{title}</div>
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <div className="my-4 cloud-charts">
          <div className="card mt-5">
            <div className="card-body p-5">
              <Table
                columns={columns}
                dataSource={reports}
                loading={tableLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleReport;
