import React, { useState, useEffect } from "react";

import { Card, Empty, Spin } from "antd";
import axios from "axios";
import { Column, Bar } from "@ant-design/plots";

export default function ItemImplemented({ projectId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    project_id: projectId,
  };

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    console.log("paramssssss", params);
    setLoading(true);
    let final_array = [];
    const url =
      " https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-items-implemented";
    await axios
      .get(url, { params })
      .then((res) => {
        console.log("response from items implemented ", res.data);
        res.data.forEach((element) => {
          let totalObj = {};
          let implementedObj = {};
          let notImplementedObj = {};
          // console.log("data inside for each", element);
          totalObj["module"] = element["Module"];
          totalObj["type"] = "Total";
          totalObj["value"] = Number(element["TotalItems"]);
          final_array.push(totalObj);
          implementedObj["module"] = element["Module"];
          implementedObj["type"] = "Implemented";
          implementedObj["value"] = Number(element["Implemented"]);
          final_array.push(implementedObj);
          notImplementedObj["module"] = element["Module"];
          notImplementedObj["type"] = "Not Implemented";
          notImplementedObj["value"] =
            Number(element["TotalItems"]) - Number(element["Implemented"]);
          final_array.push(notImplementedObj);
        });
        let x = final_array.filter((item) => item["module"] !== "Overall");
        console.log("final array for antd bar chart", x);
        setData(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
      });
  };

  const config = {
    data,
    appendPadding: 12,
    xField: "module",
    yField: "value",
    seriesField: "type",
    isGroup: true,
    barWidthRatio: 0.5,
    dodgePadding: 5,
    columnStyle: {
      radius: [0, 0, 0, 0],

      // minColumnWidth: 10,
    },
    maxColumnWidth: 30,
  };

  return (
    <Card title="Number Of Items Implemented in each module">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div
        // style={{
        //   height: data.length < 5 ? "300px" : "auto",
        // }}
        >
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return <Column {...config} />;
            }
          })()}
        </div>
      )}
    </Card>
  );
}
