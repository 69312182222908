import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card, Spin, Empty } from "antd";
import axios from "axios";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export default function HorizentalBar({ organizationId }) {
  const [chartData, setChartData] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    organization_id: organizationId,
  };

  useEffect(() => {
    setLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/org-maturity-score";
    axios
      .get(url, { params })
      .then((res) => {
        console.log("Entered use Effect", res);
        const data = res.data[0];
        let labels = Object.keys(data);
        var lastLabel = labels[labels.length - 1];
        delete data[lastLabel];
        let finalLabels = Object.keys(data);
        let values = [];
        finalLabels.forEach((key) => {
          values.push(data[key]);
        });
        setChartData(values);
        setChartLabel(finalLabels);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from maturity chart===", error);
      });
  }, []);

  const options = {
    scales: {
      x: {
        min: 0,
        max: 8,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: "PROJECTS",
          font: {
            size: 20,
          },
        },
        grid: {
          display: false,
        },
      },
    },

    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        font: {
          weight: "bold",
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Maturity Score",
        font: {
          size: 20,
        },
        padding: {
          bottom: 50,
          top: 20,
        },
      },
    },
  };

  const labels = chartLabel;

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
      },
    ],
  };

  return (
    <>
      <Card>
        {loading === true ? (
          <div className="TabsLoading">
            <Spin />
          </div>
        ) : (
          <div style={{ height: "460px" }}>
            {(() => {
              if (chartData.length === 0) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                      margin: "0 0 0 0",
                    }}
                  />
                );
              } else {
                return <Bar options={options} data={data} />;
              }
            })()}
          </div>
        )}
      </Card>
    </>
  );
}
