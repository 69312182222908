import React, { useState, useEffect } from "react";

import { Card, Empty, Spin } from "antd";
import { Bar } from "@ant-design/plots";
import axios from "axios";

export default function PercentageBar(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    project_id: props.projectId,
    module_id: props.moduleId,
  };
  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    setLoading(true);
    let final_array = [];
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/get-per-implementation";
    axios
      .get(url, { params })
      .then((res) => {
        console.log("@response from percentage implementation", res);
        // setData(res.data);
        let objects = res.data;
        // Object.keys(object).forEach((key) => {
        //   let newObj = {};
        //   //console.log(key + "-" + object[key]);
        //   newObj["process"] = key;
        //   let x = object[key].split("%")[0];
        //   newObj["percentage"] = Number(x);
        //   final_array.push(newObj);
        // });
        objects.map((obj) => {
          console.log("obj inside map", obj);
          let newObj = {};
          newObj["process"] = obj["name"];
          console.log("total percentage==", obj["total_percentage"]);
          let x = obj["total_percentage"].toFixed(2);
          newObj["percentage"] = Number(x);
          final_array.push(newObj);
        });
        console.log("final array for percentage implementation", final_array);
        setData(final_array);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module percent chart", err);
        setLoading(false);
      });
  };

  const config = {
    data,
    appendPadding: 15,
    xField: "percentage",
    yField: "process",
    //  seriesField: "process",
    maxBarWidth: 25,
    // isStack: true,
    // isPercent: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  };

  return (
    <Card title="Percentage(%) Implementation">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div>
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return <Bar {...config} />;
            }
          })()}
        </div>
      )}
    </Card>
  );
}
