import React, { useRef, useState } from "react";
import Pichart from "./Pichart";
import Barchart from "./Barchart";
import HorizentalBar from "./HorizentalBar";
import PercentageBar from "./PercentageBar";
import { Button, Card, Col, Row, message, notification } from "antd";
import BreadCrumbs from "../../BreadCrumbs";
import html2canvas from "html2canvas";
import "../../../App.css";
import axios from "axios";
import { CheckCircleOutlineTwoTone } from "@mui/icons-material";
import { routes } from "../../../util/constants";
import { useNavigate } from "react-router-dom";

export default function Chart(props) {
  const [api, contextHolder] = notification.useNotification();
  const [pieData, setPieData] = useState("");
  const [barData, setBarData] = useState("");
  const proId = props.location.state.project_id;
  const module_id = props.location.state.module_id;
  const module_name = props.location.state.module_name;
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const setPieDataForDoc = (data) => {
    console.log("inside set pie data for doc", data);
    setPieData(data);
  };
  const setBarDataForDoc = (data) => {
    console.log("inside set bar data for doc", data);
    setBarData(data);
  };
  const navigateToReports = () => {
    console.log("inside navigate to reports");
    navigate(routes.REPORTS, {
      state: {
        project_id: proId,
        module_id: module_id,
        module_name: module_name,
      },
    });
  };
  const description = (
    <>
      <a onClick={navigateToReports}>Click here to view all reports</a>
    </>
  );
  const placement = "top";
  const openNotification = () => {
    api.open({
      message: "Report Generation is in progress",
      description: description,
      placement,
      icon: (
        <CheckCircleOutlineTwoTone
          style={{
            color: "#108ee9",
          }}
        />
      ),
      duration: 0,
    });
  };
  console.log("Chart Props =======>", props);

  const [btnLoading, setBtnLoading] = useState(false);
  const [projectId, setProjectId] = useState(proId);
  const handleGenerateReport = async () => {
    console.log("inside handle generate report");
    setBtnLoading(true);
    if (imageRef.current) {
      try {
        const canvas = await html2canvas(imageRef.current);

        const base64Image = canvas.toDataURL("image/png");
        console.log("base 64 ======", base64Image);
        const params = {
          moduleId: module_id,
          projectId: proId,
          moduleName: module_name,
          imageUrl: base64Image,
          pie_data: pieData,
          bar_data: barData,
        };
        console.log("params for generate report", params);
        const url =
          "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/report";

        try {
          const resp = await axios.post(url, params);
          console.log("response from report api", resp);
        } catch (err) {
          console.log("error from report api", err);
        }
        setBtnLoading(false);
        // message.success(
        //   "Report is generated, you can view it from modules page"
        // );
        openNotification();
      } catch (error) {
        console.log("error while getting base 64", error);
      }
    }
  };

  return (
    <>
      {contextHolder}
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">Project Charts</div>
              <BreadCrumbs
                location={props.location}
                module_name={props.location.state.module_name}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginLeft: "83%" }}>
          <Button
            className="ok-modal"
            onClick={handleGenerateReport}
            loading={btnLoading}
          >
            Generate Report
          </Button>
        </Row>
        <br />
        <div ref={imageRef}>
          <Row
            gutter={[16, 16]}
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <HorizentalBar
                projectId={projectId}
                moduleId={module_id}
                setBarDataForDoc={setBarDataForDoc}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Pichart
                projectId={projectId}
                moduleId={module_id}
                setPieDataForDoc={setPieDataForDoc}
              />
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Barchart projectId={projectId} moduleId={module_id} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <PercentageBar projectId={projectId} moduleId={module_id} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
