import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, message } from "antd";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./Login.css";
import loginsideimg from "../../src/assests/img/Loginimage.jpg";
import axios from "axios";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (
        localStorage.getItem("access_level") === "ADMIN" ||
        localStorage.getItem("access_level") === "ORGANIZATION"
      ) {
        navigate("/organization");
      } else {
        navigate("/projects");
      }
    }
  }, []);

  const onChangeData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmit = () => {
    const data = { userName: userData.email, password: userData.password };
    setLoading(true);

    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/users/login-authorization";
    axios
      .post(url, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          const userData = res.data.body.userData;
          console.log("userDataaaa", userData);
          localStorage.setItem("userId", userData.user_id);
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("token", res.data.body.token);
          localStorage.setItem("access_level", res.data.body.role.name);
          message.success("user login successfully");
          const access_level = res.data.body.role.name;
          if (access_level === "ADMIN" || access_level === "ORGANIZATION") {
            navigate("/organization");
          } else {
            navigate("/projects");
          }
        } else {
          message.error(res.data.body, 2);
        }
      })
      .catch((err) => {
        message.error(err);
        console.log("error======>", err);
      });
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img src={loginsideimg} alt="Login" style={{ height: "450px" }} />
        </div>

        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome back</p>
          <p>Login to the Dashboard</p>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input
              placeholder="Email/UserName"
              name="email"
              prefix={<UserOutlined />}
              onChange={onChangeData}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              name="password"
              prefix={<LockOutlined />}
              type="password"
              onChange={onChangeData}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
              onClick={handleSubmit}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
