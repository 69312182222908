import React, { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import {
  LayoutFilled,
  AppstoreFilled,
  UserOutlined,
  DatabaseFilled,
  GoldFilled,
} from "@ant-design/icons";
import { routes } from "../../util/constants";
import logo from "../../assests/img/NewLogo.png";

export default function SiderMenu(props) {
  const access_level = localStorage.getItem("access_level");
  if (access_level === "ADMIN") {
    return (
      <div
        className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
      >
        <div className="side-header">
          <div className="brand-name">
            <div className="brand-logo">
              <div>
                <img className="logo" src={logo} alt="Logo" />
              </div>
              <span
                className="app-name"
                style={{ fontSize: "18px", margin: "6px 0px 0px 4px" }}
                mode="full"
              >
                YASH Maturity Platform
              </span>
              <span className="app-name" mode="full"></span>
            </div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
        >
          {/* <p className="menu-subhead" mode="full">
            Organization
          </p> */}
          <Menu.Item key={routes.ORGANIZATION} icon={<GoldFilled />}>
            <NavLink className="sider-links " to={routes.ORGANIZATION}>
              Organization
            </NavLink>
          </Menu.Item>
          {/* <p className="menu-subhead" mode="full">
            Table
          </p> */}
          <Menu.Item key={routes.MASTERDATA} icon={<DatabaseFilled />}>
            <NavLink className="sider-links " to={routes.MASTERDATA}>
              Master Data
            </NavLink>
          </Menu.Item>
          {/* <p className="menu-subhead" mode="full">
            User
          </p> */}
          <Menu.Item key={routes.USERS} icon={<UserOutlined />}>
            <NavLink className="sider-links " to={routes.USERS}>
              User List
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    );
  } else if (access_level === "ORGANIZATION") {
    return (
      <div
        className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
      >
        <div className="side-header">
          <div className="brand-name">
            <div className="brand-logo">
              <div>
                <img className="logo" src={logo} alt="Logo" />
              </div>
              <span
                className="app-name"
                style={{ fontSize: "18px", margin: "6px 0px 0px 4px" }}
                mode="full"
              >
                DevOps
              </span>
              <span className="app-name" mode="full"></span>
            </div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
        >
          <Menu.Item key="/dashboard" icon={<LayoutFilled />}>
            <NavLink className="sider-links " to="/dashboard">
              Dashboard
            </NavLink>
          </Menu.Item>
          <p className="menu-subhead" mode="full">
            Organization
          </p>
          <Menu.Item key={routes.ORGANIZATION} icon={<AppstoreFilled />}>
            <NavLink className="sider-links " to={routes.ORGANIZATION}>
              Organization
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    );
  } else {
    return (
      <div
        className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
      >
        <div className="side-header">
          <div className="brand-name">
            <div className="brand-logo">
              <div>
                <img className="logo" src={logo} alt="Logo" />
              </div>
              <span
                className="app-name"
                style={{ fontSize: "18px", margin: "6px 0px 0px 4px" }}
                mode="full"
              >
                DevOps
              </span>
              <span className="app-name" mode="full"></span>
            </div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
        >
          <Menu.Item key="/dashboard" icon={<LayoutFilled />}>
            <NavLink className="sider-links " to="/dashboard">
              Dashboard
            </NavLink>
          </Menu.Item>
          <p className="menu-subhead" mode="full">
            Projects
          </p>
          <Menu.Item key={routes.PROJECTSBYID} icon={<UserOutlined />}>
            <NavLink className="sider-links " to={routes.PROJECTSBYID}>
              Projects
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}
