import React, { useState } from "react";
import Pichart from "./Pichart";
import Barchart from "./Barchart";
import HorizentalBar from "./HorizentalBar";
import PercentageBar from "./PercentageBar";
import { Col, Row } from "antd";
import BreadCrumbs from "../../BreadCrumbs";
import "../../../App.css";
import Spiderchart from "./SpiderChart";

export default function OrgChart(props) {
  console.log("organization idddd", props.location.state.organization_id);
  let orgId = props.location.state.organization_id;
  const [organizationId, setOrganizationId] = useState(orgId);

  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <div className="d-flex flex-row">
              <div className="pages-header">Project Charts</div>
              <BreadCrumbs location={props.location} />
            </div>
          </Col>
        </Row>
        <br />
        <Row
          gutter={[16, 16]}
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {/* <HorizentalBar organizationId={organizationId} /> */}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {/* <Pichart organizationId={organizationId} /> */}
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{ marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Barchart organizationId={organizationId} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {/* <PercentageBar organizationId={organizationId} /> */}
            <Spiderchart organizationId={organizationId} />
          </Col>
        </Row>
      </div>
    </>
  );
}
