import {
  Button,
  Col,
  Row,
  Select,
  Spin,
  Table,
  Steps,
  message,
  Checkbox,
} from "antd";
import { Collapse } from "antd";

import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { routes } from "./../../util/constants";
import { useNavigate } from "react-router";
import MenuItem from "antd/lib/menu/MenuItem";
import { ListItemText } from "@mui/material";

const { Option } = Select;

export default function AntdCustomTable(props) {
  const [questions, setQuestions] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [queLength, setQueLength] = useState(0);
  const [finalQA, setFinalQA] = useState([]);
  const [readAns, setReadAns] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [countIs, setCountIs] = useState(0);
  const [isEdit, setIsEdit] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [queAns, setQueAns] = useState([]);
  const [disableAutomated, setDisableAutomated] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [unansweredCount, setUnansweredCount] = useState(0);
  const [process, setProcess] = useState([]);
  const [stages, setStages] = useState([]);
  const [activePanels, setActivePanels] = useState([]);
  const [enableOnEdit, setEnableOnEdit] = useState(false);
  const [booleanAns, setBooleanAns] = useState(null);
  const [multiSelectAns, setMultiSelectAns] = useState([]);
  const [QNA, setQNA] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef();
  const selectref = useRef([]);
  const qnaRef = useRef([]);
  const { Panel } = Collapse;
  const saveLoadingRef = useRef(false);
  let count = 0;

  let answers_arr = [];
  let boolean_ans = "";

  useEffect(() => {
    console.log("project iddd", props.props.state.project_id);
    getProjectData();
  }, []);

  const getProjectData = async () => {
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/customquestions";
    const params = {
      module_id: props.props.state.data.module_id,
      project_id: props.props.state.project_id,
    };

    try {
      const resp = await axios.get(url, { params });
      console.log("resssssssssss1111", resp.data.processes);
      setProcess(resp.data.processes);
    } catch (error) {
      console.log("error", error);
    }
  };

  const NestedPanel = ({ data }) => {
    console.log("data inside nested panel", data);
    let isQuestionArray = false;
    if (data?.length > 0 && data[0].hasOwnProperty("question_id")) {
      isQuestionArray = true;
      console.log("question array", isQuestionArray);
    }
    if (isQuestionArray) {
      // setDataForTable(data);
      console.log("data inside question array", data);
      let temp_data = data;
      temp_data.map((d) => {
        d["disable"] = false;
      });
      data = temp_data;
      console.log("@temp dataaaa", temp_data);
      const changeDisableForAutomated = () => {
        console.log("inside change disable for automated", data);
        let temp = data;
        let obj = temp.find((o) => o.name === "Automated");
        obj["disable"] = true;
        console.log("@final object of automated", obj);
        const i = temp.findIndex((o) => o.name === "Automated");
        temp.splice(i, 1);
        temp.push(obj);
        data = temp;
        console.log("@final data after changes in disable", data);
      };
      if (data[0].name === "Automated") {
        console.log("inside if of automated");
        const obj = data[0];
        console.log("automated obj", obj);
        let arr = [...data];
        arr.splice(0, 1);
        console.log("arr after removing automated", arr);
        arr.push(obj);
        data = arr;
        console.log("data after changing sequence", data);
      }
      const columns = [
        {
          title: "Question",
          dataIndex: "name",
          key: "name",
          width: "50%",
        },
        {
          title: "Answer",
          dataIndex: "",
          width: "50%",
          render: (text, record, index) => {
            selectref.current = [];
            if (record.answer_type === "boolean") {
              selectref.current = [];
              if (record.q_n_as && record.q_n_as.length > 0) {
                boolean_ans = record.q_n_as[0].answer;

                selectref.current.push(boolean_ans);
                // setBooleanAns(boolean_ans);
                // setActivePanels([0]);
              }
            } else {
              let parsedAnswers = [];
              if (record.q_n_as[0]?.multiselect_answers?.length > 0) {
                parsedAnswers = record.q_n_as[0].multiselect_answers.map(
                  (ans) => JSON.parse(ans)
                );
              }

              if (record.answer_type === "single_select") {
                // answers_arr = [];
                // setMultiSelectAns(parsedAnswers[0]["ans"]);
                selectref.current = [];
                if (parsedAnswers.length > 0)
                  selectref.current.push(parsedAnswers[0]["ans"]);

                // answers_arr.push(parsedAnswers[0]["ans"]);
              }
              if (record.answer_type === "multi_select") {
                console.log("answer array===", answers_arr);
                answers_arr = [];
                parsedAnswers.map((e) => answers_arr.push(e["ans"]));
                // setMultiSelectAns(answers_arr);
                selectref.current = [];
                selectref.current = answers_arr;
              }
            }
            let options = ["Yes", "No", "NA"];
            let isCustom = false;
            let isMultiSelect = false;
            if (record.answer_type === "multi_select") isMultiSelect = true;
            if (record.answers && record.answers.length > 0) {
              isCustom = true;
              const parsedAnswers = record.answers.map((ans) =>
                JSON.parse(ans)
              );
              options = [];
              parsedAnswers.map((e) => {
                options.push(e["ans"]);
              });
            }
            if (record.answer_type === "multi_select") {
              return (
                <Select
                  style={{ width: isCustom ? 520 : 150 }}
                  placeholder="Select Answer"
                  // ref={selectref}
                  mode={record.answer_type === "multi_select" ? "multiple" : ""}
                  onChange={(e) => {
                    handleAnswerChange(record, changeDisableForAutomated, e);
                  }}
                  // value={selectref.current}
                  // value={
                  //   booleanAns !== null
                  //     ? booleanAns
                  //     : multiSelectAns.length > 0
                  //     ? multiSelectAns
                  //     : selectref.current
                  // }
                  defaultValue={
                    qnaRef.current.length > 0
                      ? qnaRef.current.find(
                          (f) => f.question_id === record.question_id
                        )
                        ? qnaRef.current.find(
                            (f) => f.question_id === record.question_id
                          ).answer_type === "boolean" ||
                          qnaRef.current.find(
                            (f) => f.question_id === record.question_id
                          ).answer_type === null
                          ? qnaRef.current.find(
                              (f) => f.question_id === record.question_id
                            ).answer
                          : qnaRef.current.find(
                              (f) => f.question_id === record.question_id
                            ).only_answers
                        : selectref.current
                      : selectref.current
                  }
                  disabled={
                    (record.q_n_as?.length > 0 &&
                      !enableOnEdit &&
                      !record.disable) ||
                    (record.q_n_as?.length > 0 && record.disable)
                      ? true
                      : false
                  }
                  menuItemSelectedIcon={({ isSelected }) =>
                    isSelected ? (
                      <Checkbox checked={true} />
                    ) : (
                      <Checkbox checked={false} />
                    )
                  }
                >
                  {options.map((option, index) => (
                    <Option key={index} value={option}>
                      {option}
                    </Option>
                  ))}
                  {/* {options.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))} */}
                </Select>
              );
            }

            return (
              <Select
                style={{ width: isCustom ? 520 : 150 }}
                placeholder="Select Answer"
                // ref={selectref}
                mode={record.answer_type === "multi_select" ? "multiple" : ""}
                onChange={(e) => {
                  handleAnswerChange(record, changeDisableForAutomated, e);
                }}
                // value={selectref.current}
                // value={
                //   booleanAns !== null
                //     ? booleanAns
                //     : multiSelectAns.length > 0
                //     ? multiSelectAns
                //     : selectref.current
                // }
                defaultValue={
                  qnaRef.current.length > 0
                    ? qnaRef.current.find(
                        (f) => f.question_id === record.question_id
                      )
                      ? qnaRef.current.find(
                          (f) => f.question_id === record.question_id
                        ).answer_type === "boolean" ||
                        qnaRef.current.find(
                          (f) => f.question_id === record.question_id
                        ).answer_type === null
                        ? qnaRef.current.find(
                            (f) => f.question_id === record.question_id
                          ).answer
                        : qnaRef.current.find(
                            (f) => f.question_id === record.question_id
                          ).only_answers
                      : selectref.current
                    : selectref.current
                }
                disabled={
                  (record.q_n_as?.length > 0 &&
                    !enableOnEdit &&
                    !record.disable) ||
                  (record.q_n_as?.length > 0 && record.disable)
                    ? true
                    : false
                }
              >
                {options.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            );
          },
        },
      ];
      return (
        <Table
          className="questions-panel"
          dataSource={data}
          columns={columns}
          size="small"
          pagination={false}
        />
      );
    }
    return (
      <Collapse
        accordion={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownOutlined
            style={{ fontSize: "15px" }}
            rotate={isActive ? 180 : 0}
          />
        )}
        defaultActiveKey={activePanels}
        // activeKey={activePanels}
      >
        {data.map((item, index) =>
          item.hasOwnProperty("question_idddddddd") ? (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "200px",
                marginRight: "200px",

                justifyContent: "space-between",
              }}
            >
              <p>{item.name}:</p>
              <Select style={{ width: 120, marginLeft: "20px" }}>
                {["YES", "NO", "NA"].map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            <Panel
              header={item.name}
              key={index}
              style={{
                // border: "none",
                background: item.hasOwnProperty("category_id") ? "#FAF9F6" : "",
              }}
            >
              {item?.categories && item?.categories?.length > 0 ? (
                <NestedPanel data={item?.categories} />
              ) : item?.questions && item?.questions?.length > 0 ? (
                <NestedPanel data={item?.questions} />
              ) : item?.sub_categories && item?.sub_categories?.length > 0 ? (
                <NestedPanel data={item.sub_categories} />
              ) : item?.sub_category_items &&
                item?.sub_category_items?.length > 0 ? (
                <NestedPanel data={item.sub_category_items} />
              ) : (
                <p>No Data</p>
              )}
            </Panel>
          )
        )}
      </Collapse>
    );
  };
  const openAllPanels = () => {
    console.log("inside open all panels");
    const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    setActivePanels(arr);
  };
  const handleAnswerChange = (record, changeDisableForAutomated, e) => {
    console.log("@data inside handle answer change", changeDisableForAutomated);
    console.log("@record inside handle answer change", e, record);
    console.log("@before changing", boolean_ans);
    let multiselect_answers = [];
    let only_answers = [];
    let answer = null;
    if (record.answer_type === "boolean" || record.answer_type === null) {
      console.log("@inside booleansssssssssssss", e);
      answer = e;
      // selectref.current = e;
      // setBooleanAns(e);
      only_answers.push(e);
      if (record.name === "Implemented" && e === "No") {
        changeDisableForAutomated();
      }
    }

    if (record.answer_type === "multi_select") {
      answers_arr = [];
      answers_arr = e;
      const answers = record.answers.map((ans) => JSON.parse(ans));
      console.log("@answers in case of custom", e, answers);
      for (let i = 0; i < e.length; i++) {
        console.log("@e of i", e[i]);
        let ans_obj = answers.find((a) => a["ans"] === e[i]);
        multiselect_answers.push(ans_obj);
        console.log("@answer obj===", ans_obj);
      }
      only_answers = e;
      // selectref.current = e;
      // setMultiSelectAns(e);
      console.log("@multiselect answers", multiselect_answers);
    }
    if (record.answer_type === "single_select") {
      answers_arr = [];
      answers_arr = e;
      const options = record.answers.map((ans) => JSON.parse(ans));
      // selectref.current = e;
      setMultiSelectAns(e);
      let anso = options.find((o) => o["ans"] === e);
      multiselect_answers.push(options.find((o) => o["ans"] === e));
      only_answers.push(e);
    }

    let obj = {
      name: record.name,
      category_id: record.category_id,
      sub_category_id: record.sub_category_id,
      sub_category_item_id: record.sub_category_item_id,
      question_id: record.question_id,
      isEnabled: record.isEnabled,
      multiselect_answers: multiselect_answers,
      answer: answer,
      answer_type: record.answer_type,
      only_answers: only_answers,
    };
    // let tempList = [...QNA];
    // tempList.push(obj);
    // console.log("final temporary list", tempList);
    console.log("@before setting");
    const i = qnaRef.current.findIndex(
      (o) => o["question_id"] === obj.question_id
    );
    let arr = [];
    if (i === -1) {
      // arr = [...QNA, { obj }];
      // setQNA(arr);
      // QNA.push(obj);
      arr = [...qnaRef.current, obj];
      qnaRef.current = arr;
    } else {
      // QNA.splice(i, 1);
      // QNA.push(obj);
      // arr = [...QNA];
      // arr.splice(i, 1);
      // arr.push(obj);
      // setQNA(arr);
      arr = [...qnaRef.current];
      console.log("@splice", arr.splice(i, 1));
      arr.splice(i, 1);
      arr.push(obj);
      qnaRef.current = arr;
    }
    console.log("@final QNA", arr, qnaRef.current);
  };

  const saveCustomQNA = async () => {
    setSaveLoading(true);
    // saveLoadingRef.current = true;
    const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

    setActivePanels(arr);
    if (qnaRef.current.length === 0) {
      message.error("No changes to save");
      setSaveLoading(false);
      return;
    }
    console.log("qna inside save custom answers", qnaRef.current);

    const process_id = process[activeTab]["process_id"];
    const module_id = props.props.state.data.module_id;
    const project_id = props.props.state.project_id;
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/qna";
    const params = {
      project_id,
      module_id,
      process_id,
      QNA: qnaRef.current,
    };
    console.log("final params on save click", params);
    try {
      const res = await axios.post(url, params);
      console.log("response after save answers==", res);
      message.success("Response Saved!");
    } catch (err) {
      console.log("error from save answer", err);
    }
    setSaveLoading(false);
  };
  const updateCustomQNA = async () => {
    const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

    setActivePanels(arr);
    setSaveLoading(true);
    if (qnaRef.current.length === 0) {
      message.error("No changes to save");
      return;
    }
    console.log("qna inside save custom answers", qnaRef.current);
    let categoryId = null;
    let subCategoryId = null;
    let scmid = null;
    qnaRef.current.map((obj) => {
      let found_obj = {};
      if (obj.name === "Implemented" && obj.answer === "No") {
        console.log("#inside if of implemented whose answer is no");
        categoryId = obj.category_id;
        subCategoryId = obj.sub_category_id;
        scmid = obj.sub_category_item_id;

        if (scmid !== null) {
          // sub category item's child
          console.log("#inside sub category item's child");
          found_obj = qnaRef.current.find((o) => {
            return (
              o.sub_category_item_id === obj.sub_category_item_id &&
              o.name === "Automated" &&
              o.sub_category_id == null
            );
          });
          console.log("#found automated child of sub category item", found_obj);
          if (found_obj.answer != "NA") {
            setSaveLoading(false);
            message.error(
              "Please ensure that all implemented question's whose answers are marked as no, have NA as their corresponding automated question's answer  "
            );
            return;
          }
        }
        if (scmid == null && subCategoryId !== null) {
          // sub category's child
          console.log("#inside if of sub category's child");
          found_obj = qnaRef.current.find((o) => {
            return (
              o.sub_category_id === obj.sub_category_id &&
              o.name === "Automated" &&
              o.sub_category_item_id == null
            );
          });
          console.log("#found automated child of sub category", found_obj);
          if (found_obj.answer != "NA") {
            setSaveLoading(false);
            message.error(
              "Please ensure that all implemented question's whose answers are marked as no, have NA as their corresponding automated question's answer  "
            );
            return;
          }
        }
        if (subCategoryId == null && categoryId !== null) {
          // category's child
          console.log("#inside if of category's child");

          found_obj = qnaRef.current.find((o) => {
            return (
              o.category_id == obj.category_id &&
              o.sub_category_id == null &&
              o.name == "Automated"
            );
          });
          console.log("#found automated child of category", found_obj);
          if (found_obj.answer != "NA") {
            setSaveLoading(false);
            message.error(
              "Please ensure that all implemented question's whose answers are marked as no, have NA as their corresponding automated question's answer  "
            );
            return;
          }
        }
      }
    });

    const process_id = process[activeTab]["process_id"];
    const module_id = props.props.state.data.module_id;
    const project_id = props.props.state.project_id;
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/qna/update-qna";
    const params = {
      project_id,
      module_id,
      process_id,
      QNA: qnaRef.current,
    };
    console.log("final params on save click", params);
    try {
      const res = await axios.put(url, params);
      console.log("response after save answers==", res);
      message.success("Changes saved!");
    } catch (err) {
      console.log("error from save answer", err);
    }
    setSaveLoading(false);
  };

  const editCustomAnswers = () => {
    const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

    setActivePanels(arr);
    setEnableOnEdit(true);
  };

  // const handleStageClick = (e) => {
  //   console.log("Handle Stage Click", e.target.checked);
  // };
  useEffect(() => {
    tableRef.current.scrollTop = scrollPosition;
    // console.log("use effect");
  }, [scrollPosition]);
  const onAnswerChange = (item, record) => {
    console.log("questions inside on answer change", questions);

    const automatedQuestions = questions.filter((e) => e.name === "Automated");
    console.log("automated questions", automatedQuestions);
    let que = {};
    let flag = false;
    const tempAnsList = [...finalQA];
    if (record.name === "Implemented" && item === "No") {
      //disable automate in this case and set it's value to ns
      flag = true;
      console.log("inside no of implemented", item);
      que = automatedQuestions.find((e) => {
        if (record.sub_category_id === null) {
          return record.category_id === e.category_id;
        }
        if (record.sub_category_id !== null)
          return record.sub_category_id === e.sub_category_id;
      });

      console.log("found automated question", que);
      console.log("found automated question", que);
    }
    if (record.name === "Implemented" && item !== "No") {
      console.log("inside yes of implemented", item);
      que = automatedQuestions.find((e) => {
        if (record.sub_category_id === null) {
          return record.category_id === e.category_id;
        }
        if (record.sub_category_id !== null)
          return record.sub_category_id === e.sub_category_id;
      });
      console.log("que inside if of yes", que);
      const index = tempAnsList.findIndex(
        (obj) => obj.question_id === que.question_id
      );
      if (index !== -1) {
        console.log("automated found", tempAnsList[index]);
        // tempAnsList[index].disable = false;
        tempAnsList.splice(index, 1);
        console.log("final temp list", tempAnsList);
        setFinalQA(tempAnsList);

        let temp_questions = [...questions];
        const currentIndex = temp_questions.findIndex(
          (obj) => obj.question_id === que.question_id
        );
        console.log("temp que===", temp_questions[currentIndex]);

        temp_questions[currentIndex]["disable"] = false;
        // delete temp_questions[currentIndex].answer;
        // temp_questions[currentIndex]["answer"] = "Yes";
        console.log("is edit and answer", isEdit, temp_questions.answer);
        console.log(
          "temp que=== after setting",
          temp_questions[currentIndex],
          readAns
        );
        setIsEdit(false);
        setQuestions(temp_questions);
      }
    }

    if (flag === true) {
      console.log("inside flag== true");
      setDisableAutomated(true);

      if (!tempAnsList.find((i) => i.question_id === que.question_id)) {
        const ans = {
          question_id: que.question_id,
          name: que.name,
          answer: "na",
          disable: true,
        };
        tempAnsList.push(ans);

        console.log("temp answer lisr if new question is created", tempAnsList);
        setFinalQA(tempAnsList);
      } else {
        const index = tempAnsList.findIndex(
          (obj) => obj.question_id === que.question_id
        );
        tempAnsList.splice(index, 1);
        const ans = {
          question_id: que.question_id,
          name: que.name,
          answer: "na",
          disable: true,
        };
        tempAnsList.push(ans);
        setFinalQA(tempAnsList);
      }
      console.log("question when automated is created", questions);
      let temp_questions = [...questions];
      const currentIndex = temp_questions.findIndex(
        (obj) => obj.question_id === que.question_id
      );
      temp_questions[currentIndex]["disable"] = true;
      temp_questions[currentIndex]["answer"] = "na";
      setQuestions(temp_questions);
    }

    if (!tempAnsList.find((i) => i.question_id === record.question_id)) {
      const answer = {
        question_id: record.question_id,
        name: record.name,
        answer: item,
      };

      tempAnsList.push(answer);
      setFinalQA(tempAnsList);
    } else {
      const index = tempAnsList.findIndex(
        (obj) => obj.question_id === record.question_id
      );
      tempAnsList.splice(index, 1);
      setFinalQA(tempAnsList);
      const answer = {
        question_id: record.question_id,
        name: record.name,
        answer: item,
      };
      tempAnsList.push(answer);
      setFinalQA(tempAnsList);
    }
    // window.scrollTo(0, window.scrollY);
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      console.log("ontableScroll", scrollTop);
      setScrollPosition(scrollTop + 1);
      console.log("ontableScroll modified", scrollTop + 1);
    }

    // console.log("scroll position value", window.pageYOffset);
  };

  const onEditAnsChange = (item, record) => {
    console.log("Edit answer Called", isEdit);
    // console.log("record inside on change", record);
    // console.log("item inside on change", item);

    if (isEdit === false) {
      const newTemp = [...queAns];
      const i = newTemp.findIndex(
        (que) => que.question_id === record.question_id
      );
      console.log("new tempp", newTemp[i]);
      newTemp[i].answer = item;
    }
  };

  useEffect(() => {
    quecount();
  }, [questions]);

  const quecount = () => {
    questions?.forEach((q) => {
      if (
        q.answer === "Yes" ||
        q.answer === "No" ||
        q.answer === "NA" ||
        q.answer === "yes" ||
        q.answer === "no" ||
        q.answer === "na"
      ) {
        count++;
      }
    });
    setCountIs(count);
    const newAns = [...queAns];
    questions?.forEach((q) => {
      {
        q.answer ? newAns.push(q) : <></>;
      }
    });
    setQueAns(newAns);
  };

  const onSaveClick = async () => {
    // setSaveLoading(true);
    console.log("final qna object inside save", finalQA);
    let object = {
      project_id: props.props.state.project_id,
      module_id: apiData[activeTab].process.module_id,
      process_id: apiData[activeTab].process.process_id,
      QNA: finalQA,
    };

    console.log("Final Object", object);
    let response = await axios.post(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/qna`,
      object
    );
    console.log("Response api POST qna", response);
    if (response.data.statusCode === 201) {
      message.success("Question Saved");
      setReadAns(true);
      setFinalQA([]);
      setQueAns([]);
      setCountIs(0);
      setSaveLoading(false);
      next();
    } else {
      console.log("Error");
    }
  };

  const onSubmitClick = async () => {
    let object = {
      project_id: props.props.state.project_id,
      module_id: apiData[activeTab].process.module_id,
      process_id: apiData[activeTab].process.process_id,
      QNA: finalQA,
    };
    console.log("Final Object", object);
    let response = await axios.post(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/qna`,
      object
    );
    console.log("Response api POST qna", response);
    if (response.data.statusCode === 201) {
      message.success("All Processes Complete");
      setReadAns(true);
      navigateToCharts();
    } else {
      console.log("Error");
    }
  };

  const updateAnswers = async () => {
    setUpdateLoading(true);
    let object = {
      project_id: props.props.state.project_id,
      module_id: apiData[activeTab].process.module_id,
      process_id: apiData[activeTab].process.process_id,
      QNA: queAns,
    };
    console.log("Final Update Object", object);
    let response = await axios.put(
      `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/qna/update-qna`,
      object
    );
    console.log("Response api update qna", response);
    if (response.data.statusCode === 250) {
      message.success("Answers Updated");
      setIsEdit(true);
      setReadAns(true);
      setIsUpdate(false);
      setEditBtn(false);
      setFinalQA([]);
      setQueAns([]);
      setCountIs(0);
      quecount();
      setUpdateLoading(false);
    } else {
      console.log("Error");
    }
  };

  const editAnswers = (a, questions) => {
    setIsEdit(false);
    setReadAns(false);
    setIsUpdate(true);
    setEditBtn(true);
  };

  // const customDataColumns = [
  //   {
  //     title: "Stages",
  //     dataIndex: "name",
  //     width: 250,
  //     render: (text, record, index) => {
  //       return (
  //         <>
  //           <Row>
  //             {/* <Checkbox
  //             onChange={handleStageClick}
  //             // onClick={() => {
  //             //   handleStageClick(record);
  //             // }}
  //             style={{
  //               marginRight: 8,
  //             }}
  //           /> */}
  //             <Typography>
  //               {record.sub_category
  //                 ? record.sub_category.category.stage.name
  //                 : record.category.stage.name}
  //             </Typography>
  //             {record.sub_category?.category.stage.description ||
  //             record.category.stage.description ? (
  //               <Tooltip
  //                 title={
  //                   record.sub_category
  //                     ? record.sub_category.category.stage.description
  //                     : record.category.stage.description
  //                 }
  //               >
  //                 <InfoCircleTwoTone className="infoicon" />
  //               </Tooltip>
  //             ) : (
  //               <></>
  //             )}
  //           </Row>
  //         </>
  //       );
  //     },
  //     onCell: (record, index) => {
  //       if (
  //         index ===
  //         questions
  //           .sort(compare)
  //           .findIndex((value) =>
  //             record.sub_category_item && value.sub_category_item
  //               ? value.sub_category_item.sub_category.category.stage
  //                   .stage_id ===
  //                 record.sub_category_item.sub_category.category.stage.stage_id
  //               : value.category.stage.stage_id ===
  //                 record.category.stage.stage_id
  //           )
  //       ) {
  //         return {
  //           rowSpan: questions
  //             .sort(compare)
  //             .filter((value) =>
  //               record.sub_category_item && value.sub_category_item
  //                 ? value.sub_category_item.sub_category.category.stage
  //                     .stage_id ===
  //                   record.sub_category_item.sub_category.category.stage
  //                     .stage_id
  //                 : value.category.stage.stage_id ===
  //                   record.category.stage.stage_id
  //             ).length,
  //         };
  //       } else {
  //         return {
  //           rowSpan: 0,
  //         };
  //       }
  //     },
  //   },
  //   {
  //     title: "Categories",
  //     dataIndex: "category",
  //     width: 250,
  //     render: (text, record, index) => (
  //       <Row>
  //         {/* <Checkbox
  //           style={{
  //             marginRight: 8,
  //           }}
  //         /> */}
  //         <Typography>
  //           {record.sub_category_items
  //             ? record.sub_category_item.sub_category
  //               ? record.sub_category_items.sub_category.category.name
  //               : record.category.name
  //             : record.category.name}
  //         </Typography>
  //         {record.sub_category_items ? (
  //           record.sub_category_item.sub_category ? (
  //             record.sub_category_items.sub_category.category.description
  //           ) : (
  //             record.category.description
  //           )
  //         ) : record.category.description ? (
  //           <Tooltip
  //             title={
  //               record.sub_category_items
  //                 ? record.sub_category_item.sub_category
  //                   ? record.sub_category_items.sub_category.category
  //                       .description
  //                   : record.category.description
  //                 : record.category.description
  //             }
  //           >
  //             <InfoCircleTwoTone className="infoicon" />
  //           </Tooltip>
  //         ) : (
  //           <></>
  //         )}
  //       </Row>
  //     ),
  //     onCell: (record, index) => {
  //       if (
  //         index ===
  //         questions
  //           .sort(compare)
  //           .findIndex((value, index) =>
  //             value.sub_category_item && record.sub_category_item
  //               ? value.sub_category_item.sub_category &&
  //                 record.sub_category_item.sub_category
  //                 ? value.sub_category_item.sub_category.category
  //                     .category_id ===
  //                   record.sub_category_item.sub_category.category.category_id
  //                 : value.category.category_id ===
  //                     record.category.category_id &&
  //                   value.category.stage.stage_id ===
  //                     record.category.stage.stage_id
  //               : value.category.category_id === record.category.category_id &&
  //                 value.category.stage.stage_id ===
  //                   record.category.stage.stage_id
  //           )
  //       ) {
  //         return {
  //           rowSpan: questions
  //             .sort(compare)
  //             .filter((value, index) =>
  //               value.sub_category_item && record.sub_category_item
  //                 ? value.sub_category_item.sub_category &&
  //                   record.sub_category_item.sub_category
  //                   ? value.sub_category_item.sub_category.category
  //                       .category_id ===
  //                     record.sub_category_item.sub_category.category.category_id
  //                   : value.category.category_id ===
  //                       record.category.category_id &&
  //                     value.category.stage.stage_id ===
  //                       record.category.stage.stage_id
  //                 : value.category.category_id ===
  //                     record.category.category_id &&
  //                   value.category.stage.stage_id ===
  //                     record.category.stage.stage_id
  //             ).length,
  //         };
  //       } else {
  //         return {
  //           rowSpan: 0,
  //         };
  //       }
  //     },
  //   },
  //   {
  //     title: "Sub Categories",
  //     dataIndex: "sub_category",
  //     width: 250,
  //     render: (text, record, index) =>
  //       record.sub_category ? (
  //         <Row>
  //           {/* <Checkbox
  //             style={{
  //               marginRight: 5,
  //             }}
  //           /> */}
  //           <Typography>{record.sub_category.name}</Typography>
  //         </Row>
  //       ) : (
  //         <></>
  //       ),
  //     onCell: (record, index) => {
  //       if (
  //         record.sub_category &&
  //         record.sub_category.category &&
  //         index ===
  //           questions
  //             .sort(compare)
  //             .findIndex((value, index) =>
  //               value.sub_category && value.sub_category.category
  //                 ? value.sub_category.sub_category_id ===
  //                     record.sub_category.sub_category_id &&
  //                   value.sub_category.category.category_id ===
  //                     record.sub_category.category.category_id &&
  //                   value.sub_category.category.stage.stage_id ===
  //                     record.sub_category.category.stage.stage_id
  //                 : false
  //             )
  //       ) {
  //         return {
  //           rowSpan: questions
  //             .sort(compare)
  //             .filter((value, index) =>
  //               value.sub_category && value.sub_category.category
  //                 ? value.sub_category.sub_category_id ===
  //                     record.sub_category.sub_category_id &&
  //                   value.sub_category.category.category_id ===
  //                     record.sub_category.category.category_id &&
  //                   value.sub_category.category.stage.stage_id ===
  //                     record.sub_category.category.stage.stage_id
  //                 : false
  //             ).length,
  //         };
  //       } else if (
  //         record.category &&
  //         !record.sub_category &&
  //         index ===
  //           questions
  //             .sort(compare)
  //             .findIndex((value, index) =>
  //               value.category
  //                 ? value.question_id === record.question_id &&
  //                   value.category.category_id ===
  //                     record.category.category_id &&
  //                   value.category.stage.stage_id ===
  //                     record.category.stage.stage_id
  //                 : false
  //             )
  //       ) {
  //         return {
  //           rowSpan: 1,
  //         };
  //       } else {
  //         return {
  //           rowSpan: 0,
  //         };
  //       }
  //     },
  //   },
  //   {
  //     title: "Sub Categories Item",
  //     dataIndex: "sub_category_item",
  //     width: 180,
  //     render: (text, record, index) =>
  //       record.sub_category_item ? (
  //         <Row>
  //           {/* <Checkbox
  //             style={{
  //               marginRight: 8,
  //             }}
  //           /> */}
  //           <Typography>{record.sub_category_item.name}</Typography>
  //           {record.sub_category_item?.description ? (
  //             <Tooltip title={record.sub_category_item?.description}>
  //               <InfoCircleTwoTone className="infoicon" />
  //             </Tooltip>
  //           ) : (
  //             <></>
  //           )}
  //         </Row>
  //       ) : (
  //         <></>
  //       ),
  //     // onCell: (record, index) => {
  //     //   if (
  //     //     record.sub_category &&
  //     //     record.sub_category.category &&
  //     //     index ==
  //     //       questions
  //     //         .sort(compare)
  //     //         .findIndex((value, index) =>
  //     //           value.sub_category && value.sub_category.category
  //     //             ? value.sub_category.sub_category_id ==
  //     //                 record.sub_category.sub_category_id &&
  //     //               value.sub_category.category.category_id ==
  //     //                 record.sub_category.category.category_id &&
  //     //               value.sub_category.category.stage.stage_id ==
  //     //                 record.sub_category.category.stage.stage_id
  //     //             : false
  //     //         )
  //     //   ) {
  //     //     return {
  //     //       //   rowSpan: record.sub_category.length,
  //     //       rowSpan: questions
  //     //         .sort(compare)
  //     //         .filter((value, index) =>
  //     //           value.sub_category && value.sub_category.category
  //     //             ? value.sub_category.sub_category_id ==
  //     //                 record.sub_category.sub_category_id &&
  //     //               value.sub_category.category.category_id ==
  //     //                 record.sub_category.category.category_id &&
  //     //               value.sub_category.category.stage.stage_id ==
  //     //                 record.sub_category.category.stage.stage_id
  //     //             : false
  //     //         ).length,
  //     //     };
  //     //   } else if (
  //     //     record.category &&
  //     //     !record.sub_category &&
  //     //     index ==
  //     //       questions
  //     //         .sort(compare)
  //     //         .findIndex((value, index) =>
  //     //           value.category
  //     //             ? value.question_id == record.question_id &&
  //     //               value.category.category_id == record.category.category_id &&
  //     //               value.category.stage.stage_id ==
  //     //                 record.category.stage.stage_id
  //     //             : false
  //     //         )
  //     //   ) {
  //     //     return {
  //     //       rowSpan: 1,
  //     //     };
  //     //   } else {
  //     //     return {
  //     //       rowSpan: 0,
  //     //     };
  //     //   }
  //     // },
  //   },
  //   {
  //     title: "Implementation",
  //     dataIndex: "question",
  //     width: 250,
  //     render: (text, record, index) => {
  //       if (record.sub_category && record.sub_category.category) {
  //         return (
  //           <Row>
  //             {/* <Checkbox
  //               style={{
  //                 marginRight: 8,
  //               }}
  //             /> */}
  //             <Typography>
  //               {/* {record.question_id} */}
  //               {record.name}
  //             </Typography>
  //           </Row>
  //         );
  //       } else if (record.category && !record.sub_category) {
  //         return (
  //           <Row>
  //             {/* <Checkbox
  //               style={{
  //                 marginRight: 8,
  //               }}
  //             /> */}
  //             <Typography>
  //               {/* {record.question_id} */}
  //               {record.name}
  //             </Typography>
  //           </Row>
  //         );
  //       } else {
  //         return <></>;
  //       }
  //     },
  //     onCell: (record, index) => {
  //       if (
  //         record.sub_category &&
  //         record.sub_category.category &&
  //         index ===
  //           questions
  //             .sort(compare)
  //             .findIndex((value, index) =>
  //               value.sub_category && value.sub_category.category
  //                 ? value.question_id === record.question_id &&
  //                   value.sub_category.category.stage.stage_id ===
  //                     record.sub_category.category.stage.stage_id &&
  //                   value.sub_category.category.category_id ===
  //                     record.sub_category.category.category_id &&
  //                   value.sub_category.sub_category_id ===
  //                     record.sub_category.sub_category_id
  //                 : false
  //             )
  //       ) {
  //         return {
  //           //   rowSpan: record.question.length,
  //           rowSpan: questions
  //             .sort(compare)
  //             .filter((value, index) =>
  //               value.sub_category && value.sub_category.category
  //                 ? value.question_id === record.question_id &&
  //                   value.sub_category.category.stage.stage_id ===
  //                     record.sub_category.category.stage.stage_id &&
  //                   value.sub_category.category.category_id ===
  //                     record.sub_category.category.category_id &&
  //                   value.sub_category.sub_category_id ===
  //                     record.sub_category.sub_category_id
  //                 : false
  //             ).length,
  //         };
  //       } else if (
  //         record.category &&
  //         !record.sub_category &&
  //         index ===
  //           questions
  //             .sort(compare)
  //             .findIndex((value, index) =>
  //               value.category
  //                 ? value.question_id === record.question_id &&
  //                   value.category.category_id ===
  //                     record.category.category_id &&
  //                   value.category.stage.stage_id ===
  //                     record.category.stage.stage_id
  //                 : false
  //             )
  //       ) {
  //         return {
  //           rowSpan: 1,
  //         };
  //       } else {
  //         return {
  //           rowSpan: 0,
  //         };
  //       }
  //     },
  //   },
  //   {
  //     title: "Answer",
  //     dataIndex: "question",
  //     width: 10,
  //     render: (text, record, index) => {
  //       if (
  //         (record.sub_category && record.sub_category.category) ||
  //         (record.category && !record.sub_category)
  //       ) {
  //         return (
  //           <Select
  //             style={{ width: "120px" }}
  //             placeholder="Select answer"
  //             onChange={(item) => {
  //               onAnswerChange(item, record);
  //               onEditAnsChange(item, record);
  //             }}
  //             disabled={
  //               (record.answer && isEdit) || readAns || record.disable
  //                 ? true
  //                 : false
  //             }
  //             value={
  //               // record.name === "Automated" && disableAutomated===true
  //               //   ? "NA"
  //               //   :
  //               queAns.length === 0
  //                 ? finalQA.find((q) => q.question_id === record.question_id)
  //                   ? finalQA.find((q) => q.question_id === record.question_id)
  //                       .answer
  //                   : "Select Answer"
  //                 : queAns.find((a) => a.question_id === record.question_id)
  //                 ? queAns.find((a) => a.question_id === record.question_id)
  //                     .answer
  //                 : finalQA.find((q) => q.question_id === record.question_id)
  //                 ? finalQA.find((q) => q.question_id === record.question_id)
  //                     .answer
  //                 : "Select Answer"
  //             }
  //             options={
  //               record.answerType === null || "boolean"
  //                 ? [
  //                     {
  //                       value: "Yes",
  //                       label: "Yes",
  //                     },
  //                     {
  //                       value: "No",
  //                       label: "No",
  //                     },
  //                     {
  //                       value: "na",
  //                       label: "NA",
  //                     },
  //                   ]
  //                 : [
  //                     {
  //                       value: "1",
  //                       label: 1,
  //                     },
  //                     {
  //                       value: "2",
  //                       label: 2,
  //                     },
  //                     {
  //                       value: "3",
  //                       label: 3,
  //                     },
  //                     {
  //                       value: "4",
  //                       label: 4,
  //                     },
  //                     {
  //                       value: "5",
  //                       label: 5,
  //                     },
  //                     {
  //                       value: "na",
  //                       label: "NA",
  //                     },
  //                   ]
  //             }
  //           />
  //         );
  //       } else {
  //         return <></>;
  //       }
  //     },
  //     onCell: (record, index) => {
  //       if (
  //         record.sub_category &&
  //         record.sub_category.category &&
  //         index ===
  //           questions
  //             .sort(compare)
  //             .findIndex((value, index) =>
  //               value.sub_category && value.sub_category.category
  //                 ? value.question_id === record.question_id &&
  //                   value.sub_category.category.stage.stage_id ===
  //                     record.sub_category.category.stage.stage_id &&
  //                   value.sub_category.category.category_id ===
  //                     record.sub_category.category.category_id &&
  //                   value.sub_category.category_id ===
  //                     record.sub_category.category_id
  //                 : false
  //             )
  //       ) {
  //         return {
  //           rowSpan: questions
  //             .sort(compare)
  //             .filter((value, index) =>
  //               value.sub_category && value.sub_category.category
  //                 ? value.question_id === record.question_id &&
  //                   value.sub_category.category.stage.stage_id ===
  //                     record.sub_category.category.stage.stage_id &&
  //                   value.sub_category.category.category_id ===
  //                     record.sub_category.category.category_id &&
  //                   value.sub_category.category_id ===
  //                     record.sub_category.category_id
  //                 : false
  //             ).length,
  //         };
  //       } else if (
  //         record.category &&
  //         !record.sub_category &&
  //         index ===
  //           questions
  //             .sort(compare)
  //             .findIndex((value, index) =>
  //               value.category
  //                 ? value.question_id === record.question_id &&
  //                   value.category.category_id ===
  //                     record.category.category_id &&
  //                   value.category.stage.stage_id ===
  //                     record.category.stage.stage_id
  //                 : false
  //             )
  //       ) {
  //         return {
  //           rowSpan: 1,
  //         };
  //       } else {
  //         return {
  //           rowSpan: 0,
  //         };
  //       }
  //     },
  //   },
  // ];

  // function compare(a, b) {
  //   if (a.category.stage.name < b.category.stage.name) {
  //     return -1;
  //   }
  //   if (a.category.stage.name > b.category.stage.name) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // function compare(a, b) {
  //   if (a.category.stage.name < b.category.stage.name) {
  //     return -1;
  //   }
  //   if (a.category.stage.name > b.category.stage.name) {
  //     return 1;
  //   }
  //   if (a.category.name < b.category.name) {
  //     return -1;
  //   }
  //   if (a.category.name > b.category.name) {
  //     return 1;
  //   }
  //   return 0;
  // }
  const addFlagInItem = async (data) => {
    console.log("data inside flag in item function", data);
    // await data.forEach((process) => {
    //   console.log("pocess indie flag function", process);
    //   process.questions.forEach((question) => {
    //     question["disable"] = false;
    //   });
    // });
    // console.log("final data inside flag in item", data);
    setApiData(data[0].processes);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/question/get-all-questions?module_id=${props.props.state.data.module_id}&project_id=${props.props.state.project_id}`
      );
      console.log("reponse master", response);
      setApiData(response.data[0].processes);
      // setQuestions(response.data[0].questions);
      let count = 0;
      // response.data[0].processes.map((process) => {
      //   process.questions.map((question) => {
      //     if (!question.hasOwnProperty("answer")) count++;
      //   });
      // });
      // console.log("final count of unanswered questions", count);
      // setUnansweredCount(count);
      addFlagInItem(response.data);
      setLoading(false);
    })();
  }, []);
  const generateReport = () => {
    console.log(
      "module id inside custom table page",
      props.props.state.data.module_id
    );
    console.log(
      "project id inside generate report",
      props.props.state.project_id
    );
  };

  const next = async () => {
    setActiveTab(activeTab + 1);
    setCountIs(0);
    setReadAns(false);
    setFinalQA([]);
    setActivePanels([]);
    setQueAns([]);
  };

  const prev = () => {
    setActiveTab(activeTab - 1);
    setActivePanels([]);
    setCountIs(0);
    setReadAns(true);
    setFinalQA([]);
    setQueAns([]);
  };

  function GetTabs() {
    return (
      <>
        {loading === true ? (
          <>
            <div className="TabsLoading">
              <Spin />
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                {activeTab < apiData.length - 1 && !editBtn && (
                  <Button
                    style={{ margin: "-50px 130px 0 0" }}
                    className="ok-modal f-right"
                    disabled={questions.length !== finalQA.length + countIs}
                    // onClick={(a) => {
                    //   editAnswers(a, questions);
                    // }}
                    onClick={editCustomAnswers}
                  >
                    Edit Answers
                  </Button>
                )}
                {activeTab < apiData.length - 1 && editBtn && (
                  <Button
                    style={{ margin: "-50px 125px 0 0" }}
                    className="ok-modal f-right"
                    onClick={(a) => {
                      updateAnswers(a, questions);
                    }}
                    loading={updateLoading}
                  >
                    Update
                  </Button>
                )}{" "}
              </div>
              <div>
                <Button
                  style={{ margin: "-50px 0 0 0" }}
                  className="close-modal f-right"
                  disabled={unansweredCount > 0}
                  onClick={openAllPanels}
                >
                  View Data
                </Button>
              </div>
            </div>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div class="content" style={{ overflowX: "auto" }}>
                  <Steps
                    direction="horizontal"
                    className="step"
                    style={{ cursor: "pointer" }}
                    current={activeTab}
                    size="small"
                    items={
                      //
                      process.map((value, index) => {
                        console.log("stagesssssss", process[activeTab].stages);
                        setStages(process[activeTab].stages);
                        // setQuestions(apiData[activeTab].questions);
                        // setQueLength(apiData[activeTab].questions.length);
                        return {
                          title: (
                            <div key={index}>
                              {/* <Tooltip title={value.process.name}> */}
                              {value.name}
                              {/* </Tooltip> */}
                            </div>
                          ),
                          key: value.name,
                        };
                      })
                    }
                  />
                </div>
              </Col>
            </Row>{" "}
            <div
              style={{
                height: "400px",
                width: "auto",
                overflowY: "scroll",
                marginTop: "10px",
                // overflowX: "scroll",
              }}
              ref={tableRef}
            >
              {/* <Table
                className="tablecustom"
                columns={customDataColumns}
                dataSource={questions?.sort(compare)}
                loading={loading}
                pagination={false}
              /> */}
              <NestedPanel data={stages} />
            </div>
            <div
              style={{
                marginTop: 24,
              }}
            >
              <Row>
                <Col xl={20}>
                  {activeTab > 0 && !isUpdate && (
                    <Button
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => prev()}
                    >
                      Previous
                    </Button>
                  )}

                  {activeTab < apiData.length - 1 && !isUpdate && (
                    <Button
                      style={{
                        margin: "0 8px",
                      }}
                      // disabled={
                      //   questions.length !== finalQA.length + countIs ||
                      //   questions.length === countIs
                      // }
                      // onClick={onSaveClick}
                      onClick={enableOnEdit ? updateCustomQNA : saveCustomQNA}
                      className="ok-modal"
                      type="primary"
                      loading={saveLoading}
                      // loading={saveLoadingRef.current}
                    >
                      Save
                    </Button>
                  )}

                  {activeTab < apiData.length - 1 && !isUpdate && (
                    <Button
                      style={{
                        margin: "0 8px",
                      }}
                      type="primary"
                      className="close-modal"
                      onClick={() => {
                        next();
                      }}
                      // disabled={questions.length !== finalQA.length + countIs}
                    >
                      Next
                    </Button>
                  )}

                  {activeTab === apiData.length - 1 && !isUpdate && (
                    <Button
                      className="ok-modal"
                      type="primary"
                      // disabled={
                      //   questions.length !== finalQA.length + countIs ||
                      //   questions.length === countIs
                      // }
                      onClick={onSubmitClick}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  }

  const navigateToCharts = () => {
    const access_level = localStorage.getItem("access_level");
    if (access_level === "ADMIN" || access_level === "ORGANIZATION") {
      navigate(routes.PROJECTCHARTS, {
        state: {
          project_id: props.props.state.project_id,
        },
      });
    } else {
      navigate(routes.PROJECTMODULECHARTS, {
        state: {
          project_id: props.props.state.project_id,
        },
      });
    }
  };

  return (
    <>
      <Row>
        <Col xs={22} sm={22} md={22} lg={22} xl={22}></Col>
        <Col
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{ margin: "-25px 0 0 0" }}
        >
          {/* <div className="add-button">
            <Button
              className="btn-admin btn-subCategory event-button float-end"
              type="primary"
              style={{ background: "#5046e5" }}
              onClick={() => {
                navigateToCharts();
              }}
            >
              View Charts
            </Button>
          </div> */}
        </Col>
      </Row>

      <div className="my-4 cloud-charts">
        <div className="card mt-5">
          <div className="card-body p-5">
            <GetTabs />
          </div>
        </div>
        <br />
      </div>
    </>
  );
}
