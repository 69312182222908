import React, { useState, useEffect } from "react";
import { Bar } from "@ant-design/plots";

import { Card, Empty, Spin } from "antd";
import axios from "axios";

export default function MaturityScore({ projectId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = {
    project_id: projectId,
  };
  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    setLoading(true);
    const url =
      "https://ea1gjfrk32.execute-api.ap-south-1.amazonaws.com/dev/chart/module-maturity-score";
    await axios
      .get(url, { params })
      .then((res) => {
        console.log("response of project maturity score", res.data[0]);
        let data = res.data[0];
        console.log("data from horizontal", data);
        let final_array = [];
        Object.keys(data).forEach((key) => {
          let process_data = {};
          console.log(key + ": " + data[key]);
          process_data["name"] = key;
          process_data["value"] = data[key];
          final_array.push(process_data);
        });
        console.log("Final array for bar chart", final_array);
        let x = final_array.filter((item) => item["name"] !== "DevOps Score");
        setData(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from module chart", err);
      });
  };

  const config = {
    data,
    appendPadding: 12,
    xField: "value",
    yField: "name",
    seriesField: "name",
    maxBarWidth: 25,
    legend: {
      position: "top-left",
    },
  };

  return (
    <Card title="Maturity Score">
      {loading === true ? (
        <div className="TabsLoading">
          <Spin />
        </div>
      ) : (
        <div
        // style={{
        //   height: data.length < 5 ? "300px" : "auto",
        // }}
        >
          {(() => {
            if (data.length === 0) {
              return (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 100,
                    margin: "0 0 0 0",
                  }}
                />
              );
            } else {
              return <Bar {...config} />;
            }
          })()}
        </div>
      )}
    </Card>
  );
}
